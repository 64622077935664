import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Context } from '../../../context'
import { useTranslation } from 'react-i18next'
import Table from '../../../component/table'
import ColumnFilter from '../../../component/table/ColumnFilter'
import { GetAllHotelsData } from '../../../requests/Hotels';
import { REQUEST_STATUS } from '../../../common/constants'
import { FormateDate } from './../../../utils';
import AddPricing from './AddPricing';
import ShowHotels from './ShowHotels'

const HotelOrders = () => {

    const [data, setData] = useState([])
    const [reload, toggleReload] = useState(false)
    const [totalRows, setTotalRows] = useState(0)
    const [editedHotelId, setEditedHotelId] = useState(null)
    const [viewHotelId, setViewHotelId] = useState(null)

    const { setLoading } = useContext(Context)

    const fetchIdRef = useRef(0)
    const AddPricingRef = useRef(null)
    const ShowHotelsRef = useRef(null)

    const { t, i18n } = useTranslation()

    const columns = useMemo(() => [
        {
            Header: t('ID'),
            accessor: 'orderId',
        },
        {
            Header: t('Status'),
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {
                            <div className={`boxstatus status-order${original.statusId}`}>
                                <span className={`textstatus status${original.statusId}`}>{original.statusName}</span>
                            </div >
                        }
                    </>
                )
            }
        },
        {
            Header: t('orderDate'),
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {
                            original.orderDate ?
                                FormateDate(original.orderDate, t)
                                : "-"
                        }
                    </>
                )
            }
        },
        {
            Header: t('Name'),
            accessor: 'visitorName',
            Filter: ColumnFilter
        },

        {
            Header: t('Duration'),
            accessor: 'durationOfStay'
        },

        {
            id: 'Actions',
            Header: t('Pricing'),
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {original.statusId == REQUEST_STATUS.PendingRequest &&
                            <button className='update' onClick={() => handlePricing(original.id)}>
                                <i className='fas fa-edit'></i>
                            </button>
                        }{
                            (original.statusId == REQUEST_STATUS.Done || original.statusId == REQUEST_STATUS.ReadyQuotation) &&
                            <button className='update' title={t("Details")} onClick={() => showPrice(original.id)}>
                                <i className='fas fa-eye'></i>
                            </button>
                        }
                    </>
                )
            }
        }

    ], [i18n.language])

    const fetchData = useCallback(({ pageSize, pageIndex, filters }) => {
        const fetchId = ++fetchIdRef.current

        setLoading(l => l + 1)
        GetAllHotelsData(filters, pageIndex, pageSize, i18n.language).then(result => {
            setLoading(l => l - 1)
            if (fetchId === fetchIdRef.current && result.data.success) {
                setData(result.data.data)
                setTotalRows(result.data.totalCount)
            }
        }).catch(() => {
            setLoading(l => l - 1)
        })

    }, [reload, i18n.language])


    const handlePricing = (id) => {
        AddPricingRef.current.click()
        setEditedHotelId(id)
    }
    const showPrice = (id) => {
        ShowHotelsRef.current.click();
        setViewHotelId(id)
    }

    return (
        <div className='Clearance-TableContainer'>

            <AddPricing modalID={AddPricingRef} reload={reload} toggleReload={toggleReload} RecordID={editedHotelId} finishOperation={() => AddPricingRef.current.click()} />
            <ShowHotels modalID={ShowHotelsRef} reload={reload} toggleReload={toggleReload} hotelId={viewHotelId} finished={() => ShowHotelsRef.current.click()} />

            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                TotalRows={totalRows}
            />
        </div>
    )
}
export default HotelOrders

