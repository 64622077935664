import React, { useEffect, useRef } from 'react'
import "./style.css"
import { useTranslation } from 'react-i18next'

const FileInput = ({ accept,value, setValue, label }) => {
    const inputRef = useRef()

    useEffect(() => {
        if (value)
            return
        inputRef.current.value = ""
    }, [value])

    return (
        <div className='field-control'>
            <label>
                {label}
            </label>
            <input accept={accept} ref={inputRef} className={'col-12'} type={'file'} onChange={e => setValue(e.target.files[0])} />
        </div>
    )
}

export default FileInput