import React, { useContext, useEffect, useState } from 'react'
import Popup from '../../component/popup'
import { Context } from '../../context'
import { useTranslation } from 'react-i18next'
import Field from '../../component/field'
import { GetServiceTypeLookUp } from '../../requests'
import { toast } from 'react-toastify'
import { ConfirmAlert } from '../../component/confirmAlert'
import MultiSelect from '../../component/multi-select'
import { AddServiceCategory, GetserviceCatgoryById, updateServiceCategory } from '../../requests/ServiceCategory'
import { Paths } from '../../common/constants'
import { validateImg } from '../../utils'
import FileInput from '../../component/FileInput'

const modalName = 'HospitalModel'

const CUServiceCategory = ({ RecordID, finishOperation, toggleReload, modalID }) => {

    const { loading, setLoading } = useContext(Context)
    const [name, setName] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [ServiceTypeLookUp, setServiceTypeLookUp] = useState([])
    const [selectedServiceType, setSelectedServiceType] = useState([])
    const [serviceCatgoryImg, setServiceCatgoryImg] = useState(null)
    const [currentServiceCatgoryImg, setCurrentServiceCatgoryImg] = useState(null)
    const [serviceIcon, setServiceIcon] = useState(null)
    const [currentServiceIcon, setCurrentServiceIcon] = useState(null)

    const { i18n, t } = useTranslation()

    useEffect(() => {
        if (!RecordID)
            return
        setLoading(l => l + 1)
        GetserviceCatgoryById(RecordID).then(res => {
            let hopsitalDetails = res.data.data
            setName(hopsitalDetails.name)
            setNameEn(hopsitalDetails.nameEN)
            setCurrentServiceCatgoryImg(hopsitalDetails.image)
            setCurrentServiceIcon(hopsitalDetails.icon)
            setSelectedServiceType(ServiceTypeLookUp.find(ele => ele.value == hopsitalDetails.serviceTypeId))
            setLoading(l => l - 1)
        }).catch(err => {
            setLoading(l => l - 1)
        })

    }, [RecordID])

    useEffect(() => {
        setLoading(l => l + 1)
        GetServiceTypeLookUp(i18n.language).then(res => {
            setServiceTypeLookUp(res.data.data)
            setLoading(l => l - 1)
        }).catch(err => {
            setLoading(l => l - 1)
        })
    }, [])


    const saveService = () => {

        if (!name || !nameEn || !selectedServiceType) {
            toast.error(t("Please fill all fields"))
            return
        }

        let FormObj = new FormData()


        if (((!validateImg(serviceCatgoryImg) && !currentServiceCatgoryImg) || (!validateImg(serviceIcon) && !currentServiceIcon))) {
            toast.error(t("Please enter a valide img"))
            return
        }

        FormObj.append('Image', serviceCatgoryImg)
        FormObj.append('Icon', serviceIcon)

        FormObj.append('Name', name)
        FormObj.append('NameEN', nameEn)
        FormObj.append('ServiceTypeId', selectedServiceType?.value)

        ConfirmAlert(RecordID ? t('Edit ServiceCategory') : t('Add ServiceCategory'),
            RecordID ? 'Edit This ServiceCategory?' : 'Add This ServiceCategory?', () => {
                if (RecordID) {

                    setLoading(l => l + 1)
                    updateServiceCategory(RecordID, FormObj).then(res => {
                        toast.success(t('success updating Service category'))
                        finishOperation()
                        toggleReload(e => !e)
                        setLoading(l => l - 1)
                    }).catch(err => {
                        setLoading(l => l - 1)
                    })
                } else {

                    setLoading(l => l + 1)
                    AddServiceCategory(FormObj).then(res => {
                        toast.success(t('success Add Service category'))
                        finishOperation()
                        toggleReload(e => !e)
                        setLoading(l => l - 1)
                    }).catch(err => {
                        setLoading(l => l - 1)
                    })
                }
            }, t
        )
    }

    const resetFields = () => {
        setName("")
        setNameEn("")
        setSelectedServiceType(null)
        setServiceCatgoryImg(null)
        setServiceIcon(null)
    }

    return (
        <>
            <button ref={modalID} data-toggle="modal" data-target={`#${modalName + RecordID}`} hidden></button>
            <Popup name={modalName + RecordID} large={true} title={RecordID ? t("Edit ServiceCategory") : t("Add ServiceCategory")}
                PopupName={t("Role")} t={t}>
                <div className='row'>
                    <Field className={'col-md-6'} label={t('Name')} value={name} setValue={setName} />
                    <Field className={'col-md-6'} label={t('English Name')} value={nameEn} setValue={setNameEn} />
                </div>
                <div className='row'>
                    <MultiSelect isMulti={false} label={t('ServiceCategories')} options={ServiceTypeLookUp} setSelectedOptions={setSelectedServiceType} selectedOptions={selectedServiceType} />
                    {!currentServiceCatgoryImg &&
                        <div className='col-lg-6'>

                            <FileInput label={t('ServiceCategoryImgs')} setValue={setServiceCatgoryImg} value={serviceCatgoryImg} />
                        </div>
                    }
                    {currentServiceCatgoryImg &&
                        <>
                            <img src={Paths.DashboardServer + currentServiceCatgoryImg} alt='Img' className='pupupImg' />
                            <button style={{ backgroundColor: "transparent" }} className='delete' onClick={() => { setCurrentServiceCatgoryImg(null) }} >
                                <i className='fas fa-close'></i>
                            </button>
                        </>
                    }
                </div>
                <div className='row'>
                    {!currentServiceIcon &&
                        <div className='col-lg-6'>
                            <FileInput label={t('ServiceCategoryIcon')} setValue={setServiceIcon} value={serviceIcon} />
                        </div>
                    }

                    {currentServiceIcon &&
                        <>
                            <img src={Paths.DashboardServer + currentServiceIcon} alt='Img' className='pupupImg' />
                            <button style={{ backgroundColor: "transparent" }} className='delete' onClick={() => { setCurrentServiceIcon(null) }} >
                                <i className='fas fa-close'></i>
                            </button>
                        </>
                    }
                </div>
                <div className="buttons">
                    <button className=" ActionBtn my-2 my-sm-0" onClick={saveService} disabled={loading}>
                        {RecordID ? t("Update") : t("Save")}
                    </button>
                </div>
            </Popup>
        </>
    )
}

export default CUServiceCategory