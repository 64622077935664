import React, { useContext, memo, useState, useEffect } from 'react';
import { Context } from '../../context';
import { useTranslation } from "react-i18next";
import { GetUserDetails, Logout } from "../../requests";
import './style.css';
import { Icon } from '@iconify/react';
import { GetUserId } from '../../utils';
import DatePickerRange from '../DateRangePicker';

const Header = () => {
    const [userTab, setUserTab] = useState(false)

    const { setIsLoggedIn, userName, setUserName, isLoggedIn, showDateRange } = useContext(Context)
    const { t, i18n } = useTranslation()

    const logout = () => {
        Logout().then(result => {
            if (result.data.success) {
                localStorage.clear()
                setIsLoggedIn(false)
            }
        })
    }
    const changeLang = () => {
        if (i18n.language === "ar")
            i18n.changeLanguage('en')
        else
            i18n.changeLanguage('ar')
    }

    useEffect(() => {
        GetUserDetails(GetUserId()).then(res => {
            setUserName(res.data.data.name)
            localStorage.setItem('userName', res.data.data.name)
        })
    }, [isLoggedIn])

    return (
        <nav className="navbar navbar-expand-lg ">
            <div className="container-fluid">
                <div className='Right-Part'>

                    <div className="logo"><img src="./images/Logo.png?v=1" alt="" /></div>
                    {
                        showDateRange ?
                            <DatePickerRange /> : ""
                    }
                </div>

                <div className={`leftPart ${i18n.language}`} onClick={() => setUserTab(e => !e)}>

                    <div className='userDetails'>
                        <div className='userInfo'>
                            <span className='userName'>
                                {userName}
                            </span>
                        </div>
                        <Icon icon="octicon:person-16" className='accountIcon Icon' />
                    </div>


                    <button className='userSetting' >
                        <Icon icon={`fluent:chevron-${!userTab ? "down" : "up"}-16-filled`} />
                    </button>
                    {userTab &&
                        <div className='userSettingsMenu'>
                            <div className='logOut' onClick={logout}>
                                <span className='HeaderText'>
                                    {t("logout")}
                                </span>
                                <Icon icon="lucide:door-open" className='Icon' />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </nav>
    );
}

export default memo(Header);