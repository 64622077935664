import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import { useTable, usePagination, useFilters } from "react-table";
import { useTranslation } from "react-i18next";
import "./style.css";
import { Icon } from "@iconify/react/dist/iconify.js";

const PAGE_SIZE = 20;
const PaggingScale = 5

const Table = ({
  columns,
  data,
  fetchData,
  TotalRows,
  hiddenColumns,
  assignRelatedClass,
  isPagination = true,
  page_size = PAGE_SIZE,
  hideActions,
  exportExcel,
}) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false)
  const [paginationPages, setPaginationPages] = useState([])

  const handleHide = useMemo(() => {
    let arr = []
    if (hideActions) {
      arr.push("actions")
    }
    if (hiddenColumns) {
      arr.push(hiddenColumns)
    }
    arr.push("")
    return arr
  }, [hideActions, hiddenColumns])

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    headerGroups,
    page,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    gotoPage,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns,
      data,
      pageCount: Math.ceil(TotalRows / page_size),
      initialState: {
        pageIndex: 0,
        pageSize: page_size || 20,
        hiddenColumns: handleHide,
      },
      defaultColumn: { Filter: "" },
      manualPagination: true,
      manualFilters: true,
    },
    useFilters,
    usePagination
  );

  useEffect(() => {
    if (isPagination) fetchData({ pageIndex, pageSize, filters });
  }, [fetchData, pageIndex, pageSize, isPagination, filters]);

  const handleExportExcel = () => {
    setIsLoading(true)
    exportExcel(filters, i18n.language).then(() => setIsLoading(false)).catch(() => setIsLoading(false))
  }


  useEffect(() => {
    let tempArr = []
    if (TotalRows / page_size >= 0) {

      for (var i = 2; i <= Math.ceil(TotalRows / page_size); i++) {
        tempArr.push(i)
      }
      setPaginationPages([...tempArr])
    }
  }, [TotalRows, page_size])


  return (
    <div className="TableContainer">
      {exportExcel ? (
        <button
          className="btnTableRelevant"
          onClick={handleExportExcel}
          disabled={isLoading}
        >
          <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
          {t("Export")}
        </button>
      ) : null}
      <div className="pagination">
        {
          TotalRows ?
            <div className="displayedRowsCount">
              {`${TotalRows ? 1 + pageSize * pageIndex : 0} ${t("to")} ${TotalRows == 0 ? 0 : Math.min(pageSize * (pageIndex + 1), TotalRows)} ${t("row")} ${t("of")} ${TotalRows} ${t("row")}`}
            </div> : ''
        }
      </div>

      <div className="wrapperTable">
        <table {...getTableProps()} >
          <thead  >
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} className={column.id == 'actions' ? "stickyCol" : ''} >
                    {column.render("Header")}
                    <div className="minWidh">
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()} style={{ backgroundColor: "#fff" }}>
            {page.map((row, i) => {
              prepareRow(row);

              return (
                <tr {...row.getRowProps()} className={`${assignRelatedClass ? assignRelatedClass(row.cells) : ''}`}>
                  {row.cells.map((cell, index) => {
                    return <td {...cell.getCellProps()} className={cell.column.id === 'actions' ? "sticky" : ""}>{cell.render("Cell")}</td>
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>

      </div>

      {
        (isPagination && TotalRows) ? (
          <div className={`tableFooter ${i18n.language} `}>
            <Icon icon='ic:round-chevron-right' className={`pagginationArrow ${canPreviousPage ? '' : 'disabled'} `} onClick={() => canPreviousPage ? previousPage() : {}} />
            <div className="pageCardsContainer">
              <div className={`${(pageIndex === 0) && 'selectedPage'} PageNumCard`} onClick={() => gotoPage(0)} >
                1
              </div>
              {
                paginationPages.slice(paginationPages.length == pageIndex ? pageIndex - (PaggingScale - 2) : pageIndex - 1 > 0 ? pageIndex - 1 : 0, pageIndex + PaggingScale).map((p) => {
                  return (
                    <div key={p} className={`${pageIndex === p - 1 && 'selectedPage'} PageNumCard`} onClick={() => gotoPage(p - 1)} >
                      {p}
                    </div>
                  )
                }
                )
              }
              {paginationPages.length ?
                <>
                  {pageIndex <= paginationPages.length - (PaggingScale + 2) ?
                    <>
                      <div>
                        {'...'}
                      </div>

                    </>
                    : ""
                  }
                  {pageIndex <= paginationPages.length - (PaggingScale + 1) ?
                    <>
                      <div className={`${pageIndex == paginationPages.length && 'selectedPage'} PageNumCard`} onClick={() => gotoPage(paginationPages.length)} >
                        {paginationPages.slice(-1)[0]}
                      </div>
                    </>
                    : ""
                  }
                </>
                : ""}
            </div>
            <Icon icon='ic:round-chevron-left' className={`pagginationArrow ${canNextPage ? '' : 'disabled'} `} onClick={() => canNextPage ? nextPage() : {}} />
          </div>
        ) : ''
      }
    </div >
  );
};

export default memo(Table);
