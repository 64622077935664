import React from 'react'

const HeaderName = ({ PageName }) => {
    return (
        <div className='PageHeader'>
            <div className='PageName'>
                {PageName}
            </div>
            {/* ADD Date Picker */}
        </div>
    )
}

export default HeaderName