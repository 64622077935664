import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../containers/home";
import Login from "../containers/login";
import VerifyAccount from "../containers/login/verifyAccount";
import ForgetPassword from "../containers/login/forgetPassword";
import ResetPassword from "../containers/login/resetPassword";


import { Context } from "../context";
import HotelOrders from './../containers/Orders/HotelOrders/index';
import TransportationOrders from './../containers/Orders/TransportationOrders/index';

const RoutesC = () => {
  const { isLoggedIn } = useContext(Context);

  return (
    <Routes>
      <Route path="login" element={!isLoggedIn ? <Login /> : <Navigate to="/" />} />
      <Route path="forgetPassword" element={<ForgetPassword />} />
      <Route path="verifyAccount" element={<VerifyAccount />} />
      <Route path="resetPassword" element={<ResetPassword />} />
      <Route path="/*" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
    </Routes>
  );
};

export default RoutesC;