import React, { useState, useEffect, useContext, useRef, memo } from 'react';
import Popup from '../../component/popup';
import { GetRolesLookup, AddUser, UpdateUser, GetUserDetails } from '../../requests'
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { ValidateEmail } from '../../utils'
import { Context } from "../../context";
import Field from '../../component/field';
import { ConfirmAlert } from '../../component/confirmAlert';
import MultiSelect from '../../component/multi-select'

const modalName = "CUUserModal"

const CUUser = ({ userID, modalID, finishOperation, loadData }) => {
    const [roles, setRoles] = useState([]);
    const [name, setName] = useState('')
    const [username, setUsename] = useState('')
    const [email, setEmail] = useState('')
    const [role, setRole] = useState(null)
    const [isLoading, setisLoading] = useState(false)

    const { setLoading } = useContext(Context)
    const { t, i18n } = useTranslation()
    const loadedData = useRef(false)

    useEffect(() => {
        if (userID && roles.length > 0) {
            setLoading(l => l + 1)
            GetUserDetails(userID).then(result => {
                setLoading(l => l - 1)

                if (result.data.success) {
                    var User = result.data.data;
                    setName(User.name)
                    setEmail(User.email)
                    setUsename(User.username)
                    setRole(roles.find(r => r.value === User.roleId))
                }
            }).catch(error => {
                setLoading(l => l - 1)
                toast.error(error.response?.data.message || error.message)
            })
        }
    }, [userID, roles])

    useEffect(() => {
        if (loadedData.current || (!userID && !loadData))
            return

        loadedData.current = true

        getRolesLookup()
    }, [userID, loadData])

    useEffect(() => {
        if (loadedData.current)
            getRolesLookup()
    }, [i18n.language])

    const getRolesLookup = () => {
        setLoading(l => l + 1)
        GetRolesLookup(i18n.language).then(result => {
            setLoading(l => l - 1)
            if (result.data.success)
                setRoles(result.data.data)
        }).catch(() => {
            setLoading(l => l - 1)
        })
    }

    console.log(roles)
    const saveUser = () => {
        if (name.trim() === '' || username.trim() === '' || email.trim() === '' || !role) {
            toast.error(t("Please fill all fields"))
            return
        }

        if (!ValidateEmail(email)) {
            toast.error(t("Email is not valid"))
            return
        }

        ConfirmAlert(userID ? t("Edit User") : t("Add User"),
            userID ? "Edit this User?" : "Add this User?",
            () => {
                const UserData = {
                    name: name,
                    username: username,
                    email: email,
                    roleId: role.value
                }

                setisLoading(true);
                if (userID) {
                    UpdateUser(userID, UserData).then(result => {
                        if (result.data.success) {
                            setisLoading(false);
                            toast.success(t("User Edited Successfully"))
                            finishOperation();
                        }
                    }).catch(() => {
                        setisLoading(false)
                    })
                }
                else {
                    AddUser(UserData).then(result => {
                        if (result.data.success) {
                            setisLoading(false);
                            toast.success(t("User Added Successfully"));
                            finishOperation();
                        }
                    }).catch(() => {
                        setisLoading(false);
                    })
                }
            }, t)
    }

    const resetAllFields = () => {
        setName('')
        setEmail('')
        setUsename('')
        setRole(null)
    }

    return (
        <>
            <button ref={modalID} data-toggle="modal" data-target={`#${modalName + userID}`} hidden></button>
            <Popup name={modalName + userID} large={true} title={userID ? t("Edit User") : t("Add User")}
                onClose={userID ? undefined : resetAllFields}>

                <Field id="name" value={name} setValue={setName} label={t("Name")} />
                <Field id="usename" value={username} setValue={setUsename} label={t("UserName")} />
                <Field id="name" value={email} setValue={setEmail} label={t("Email")} />

                <MultiSelect isMulti={false} options={roles} setSelectedOptions={setRole} selectedOptions={role} label={t("Role")} placeholder={t("Role")} />

                <div className="buttons">
                    <button className="ActionBtn my-2 my-sm-0" onClick={saveUser} disabled={isLoading}>
                        <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                        {userID ? t("Update") : t("Save")}
                    </button>
                </div>
            </Popup>
        </>
    );
}

export default memo(CUUser);
