import React, { memo } from 'react';
import './style.css';

const Popup = ({ name, title, large, onClose, children }) => {
    return (
        <div className={`modal fade popup`} id={name} tabIndex="-1" role="dialog" aria-hidden='true' onClick={onClose}>
            <div className={`modal-dialog ${large ? "modal-xl" : ""}`} role="document">
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-body">
                        <div className="title">{title}</div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Popup);