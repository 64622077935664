import React, { useContext, useEffect, useRef, useState } from 'react'
import Popup from '../../component/popup'
import { Context } from '../../context'
import { useTranslation } from 'react-i18next'
import Field from '../../component/field'
import { AddHospitals, GetHospitalsById, GetGovernmentLookup, updateHospitalById } from '../../requests'
import { toast } from 'react-toastify'
import { ConfirmAlert } from '../../component/confirmAlert'
import MultiSelect from '../../component/multi-select'
import { serviceCatgoryLookUp } from '../../requests/ServiceCategory'
import { Paths } from '../../common/constants'
import FileInput from '../../component/FileInput'
import { validateImg } from '../../utils'
import TextArea from '../../component/TextArea'


const modalName = 'HospitalModel'

const CUHospitals = ({ RecordID, finishOperation, toggleReload, modalID }) => {

    const { loading, setLoading } = useContext(Context)
    const [name, setName] = useState('')
    const [nameEn, setNameEn] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionEn, setDescriptionEn] = useState('')
    const [ServiceCategoryLookUp, setServiceCategoryLookUp] = useState([])
    const [selectedServiceCategories, setSelectedServiceCategories] = useState([])
    const [governmentsLookup, setGovernmentsLookup] = useState([])
    const [hopsitalImg, setHopsitalImg] = useState(null)
    const [currentHospitalImg, setcurrentHositalImg] = useState(null)
    const [selectedGovenment, setSelectedGovenment] = useState(null)

    const hopsitalImgRef = useRef()

    const { i18n, t } = useTranslation()

    useEffect(() => {
        if (!RecordID)
            return
        setLoading(l => l + 1)


        GetHospitalsById(RecordID).then(res => {
            let hopsitalDetails = res.data.data
            setName(hopsitalDetails.name)
            setNameEn(hopsitalDetails.nameEN)
            setDescription(hopsitalDetails.description)
            setDescriptionEn(hopsitalDetails.descriptionEN)
            setcurrentHositalImg(hopsitalDetails.image)
            setSelectedGovenment(governmentsLookup.find(gov => gov.value == hopsitalDetails.governmentId))
            setSelectedServiceCategories(hopsitalDetails.services.filter(ele => ServiceCategoryLookUp.some(l => l.value = ele.value)))
            setLoading(l => l - 1)
        }).catch(err => {
            setLoading(l => l - 1)
        })

    }, [RecordID])

    useEffect(() => {
        setLoading(l => l + 2)
        serviceCatgoryLookUp(i18n.language).then(res => {
            setServiceCategoryLookUp(res.data.data)
            setLoading(l => l - 1)
        }).catch(err => {
            setLoading(l => l - 1)
        })


        GetGovernmentLookup(i18n.language).then(res => {
            setGovernmentsLookup(res.data.data)
            setLoading(l => l - 1)
        }).catch(err => {
            setLoading(l => l - 1)
        })

    }, [i18n.language])


    const saveService = () => {

        if (!name || !nameEn || !description || !descriptionEn || !selectedGovenment?.value || selectedServiceCategories.length == 0) {
            toast.error(t("Please fill all fields"))
            return
        }


        let FormObj = new FormData()

        if (!validateImg(hopsitalImg) && !currentHospitalImg) {
            toast.error(t("Please enter a valide img"))
            return
        }
        FormObj.append('Image', hopsitalImg)

        FormObj.append('Name', name)
        FormObj.append('NameEN', nameEn)
        FormObj.append('Description', description)
        FormObj.append('DescriptionEN', descriptionEn)
        FormObj.append('ServiceCategoryIds', selectedServiceCategories.map(ele => ele.value))
        FormObj.append('GovernmentId', selectedGovenment.value)

        ConfirmAlert(RecordID ? t('Edit Hospital') : t('Add Hospital'),
            RecordID ? 'Edit This Hospital?' : 'Add This Hospital?', () => {
                if (RecordID) {

                    setLoading(l => l + 1)
                    updateHospitalById(RecordID, FormObj).then(res => {
                        if (res.data.success) {
                            toast.success(t('success updating Hospital'))
                            toggleReload(e => !e)
                            finishOperation()
                        }
                    })
                        .catch(err => { })
                        .finally(() => setLoading(l => l - 1))
                } else {
                    setLoading(l => l + 1)
                    AddHospitals(FormObj).then(res => {
                        if (res.data.success) {
                            toast.success(t('success adding Hospital'))
                            resetFields()
                            toggleReload(e => !e)
                            finishOperation()
                        }
                    })
                        .catch(err => { })
                        .finally(() => setLoading(l => l - 1))
                }
            }, t
        )
    }

    const resetFields = () => {
        setName("")
        setNameEn("")
        setDescription("")
        setDescriptionEn("")
        setSelectedServiceCategories([])
        setHopsitalImg(null)
        setSelectedGovenment(null)
    }



    return (
        <>
            <button ref={modalID} data-toggle="modal" data-target={`#${modalName + RecordID}`} hidden></button>
            <Popup name={modalName + RecordID} large={true} title={RecordID ? t("Edit Hospital") : t("Add Hospital")}
                PopupName={t("Role")} t={t}>
                <div className='row'>
                    <Field className={'col-md-6'} label={t('Name')} value={name} setValue={setName} />
                    <Field className={'col-md-6'} label={t('English Name')} value={nameEn} setValue={setNameEn} />

                </div>
                <div className='row'>

                    <MultiSelect isMulti={false} label={t('Govenments')} options={governmentsLookup} setSelectedOptions={setSelectedGovenment} selectedOptions={selectedGovenment} />
                    <MultiSelect label={t('ServiceCategories')} options={ServiceCategoryLookUp} setSelectedOptions={setSelectedServiceCategories} selectedOptions={selectedServiceCategories} />

                    <TextArea type={'Ar'} label={t('description')} value={description} setValue={setDescription} />
                    <TextArea type={'En'} label={t('descriptionEN')} value={descriptionEn} setValue={setDescriptionEn} />

                </div>
                <div className='row'>

                    {!currentHospitalImg &&
                        <div className='col-lg-6'>

                            <FileInput accept={'image/png, image/jpeg, image/jpg'} label={t('HopsitalImg')} setValue={setHopsitalImg} value={hopsitalImg} /> </div>
                    }
                    {currentHospitalImg &&
                        <>
                            <img src={Paths.DashboardServer + currentHospitalImg} alt='Img' className='pupupImg' />
                            <button style={{ backgroundColor: "transparent" }} className='delete' onClick={() => { setcurrentHositalImg(null) }} >
                                <i className='fas fa-close'></i>
                            </button>
                        </>
                    }
                </div>
                <div className="buttons">
                    <button className=" ActionBtn my-2 my-sm-0" onClick={saveService} disabled={loading}>
                        {RecordID ? t("Update") : t("Save")}
                    </button>
                </div>
            </Popup>
        </>
    )
}

export default CUHospitals