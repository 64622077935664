
export const CONTROLLER_NAMES = {
    User: 1,
    Roles: 2,
    ServiceType: 3,
    ServiceCategory: 4,
    MedicalPendingOrder: 5,
    Hospital: 8,
    Hotels: 9,
    Transactions: 10
}

export const SERVICES_TYPES = {
    Medical: 1,
    Hotel: 2,
    Transportation: 3,
    Tour: 4,
    TeleConsultation: 5,
}
export const HOTEL_TYPE = {
    Rooms: 1,
    Beds: 2,
}

export const REQUESTS_SCREENS = [
    { title: [SERVICES_TYPES.Medical], content: "hospitalOrders" },
    { title: [SERVICES_TYPES.Hotel], content: "hotelOrders" },
    { title: [SERVICES_TYPES.Transportation], content: "transportaionOrders" },
    { title: [SERVICES_TYPES.Tour], content: "tourOrders" },
    // { title: [SERVICES_TYPES.TeleConsultation], content: "teleConsultations" },
]

export const Paths = {
    WebServer: 'https://htapi.jozoortech.com/public/Files/',
    DashboardServer: 'https://htapidash.jozoortech.com/public/Files/'
}

export const REQUEST_STATUS = {
    ReadyQuotation: 1,
    WaitingClearanceApproval: 2,
    WaitingPartialPayment: 3,
    PartialPaymentDone: 4,
    Done: 5,
    Rejected: 6,
    WaitingPayment: 7,
    NeedClearanceApproval: 8,
    PendingRequest: 9,
    waitingAvailableDates: 10,
    AvailableDatesReady: 11,
    BookingRequestDone: 12,
    BookingConfirmed: 13,
    AdditionalDocumentsRequired: 14,

}
