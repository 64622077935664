import { APICall } from './index';
import fileDownload from 'js-file-download';

const ControllerName = "user"

export const ExportUsersExcel = (filters, lang) => {
    var username = filters.find(f => f.id === "username")?.value || '';
    var email = filters.find(f => f.id === "email")?.value || '';

    return APICall("get", ControllerName + "/excel?username=" + username + "&email=" + email, null, lang)
        .then(result => {
            return new Promise((resolve) => {
                fileDownload(result.data, 'users.xlsx')
                resolve()
            })
        })
}

export const GetAllUsers = (pageSize, pageIndex, filters = []) => {

    var username = filters.find(f => f.id === "username")?.value || '';
    var email = filters.find(f => f.id === "email")?.value || '';

    return APICall("get", ControllerName + "?page=" + pageIndex + "&pageLength=" + pageSize + "&sort=Id:-1" + "&username=" + username + "&email=" + email)
}

export const GetUserDetails = (userID) => {
    return APICall("get", ControllerName + '/' + userID)
}

export const AddUser = (UserData) => {
    return APICall("post", ControllerName, UserData)
}

export const UpdateUser = (Userid, UserData) => {
    return APICall("put", ControllerName + "/" + Userid, UserData)
}

export const DeleteUser = (Userid) => {
    return APICall("delete", ControllerName + "/" + Userid)
}

export const Register = (UserData) => {
    return APICall("post", ControllerName + "/register", UserData)
}

export const ChangePassword = (PasswordData) => {
    return APICall("patch", ControllerName + "/changePassword", PasswordData)
}

export const ForgetPassword = (Email) => {
    return APICall("patch", ControllerName + "/ForgetPassword?Email=" + Email)
}

export const ResetPassword = (ResetPasswordData) => {
    return APICall("patch", ControllerName + "/ResetPassword", ResetPasswordData)
}

export const Login = (userInfo, lang) => {
    return APICall("post", "auth/token", userInfo, lang)
}

export const Logout = () => {
    return APICall("post", "auth/logout", {})
}

export const VerifyAccount = (code) => {
    return APICall("patch", ControllerName + "/verifyAccount?code=" + code)
}


