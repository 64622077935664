import jwt_decode from "jwt-decode";
import moment from 'moment'

export const ToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export const ValidatePhone = (mobile) => {
  return /^01[0125][0-9]{8}$/g.test(mobile)
}

export const ValidatePassword = (password) => {
  return /^(?=.*\d)(?=.*[a-z])(?=.*[@#$%!^&])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/g.test(password)
}

export const ValidatePdf = (Path) => {
  let fileExtension = Path?.type
  let forImg = Path?.type.split('/')[0]
  let AllowedExtension = ['application/pdf']
  return AllowedExtension?.includes(fileExtension) || AllowedExtension?.includes(forImg)
}

export const GetUserId = () => {
  if (!localStorage.getItem("ut")) {
    return null
  } else {
    return JSON.stringify(jwt_decode(localStorage.getItem("ut"))).split("nameidentifier\":")[1].split(',')[0].split('"')[1]

  }
}


export const validateImg = (Img) => {
  return Img?.type?.includes('image/')
}

export const ValidateSSN = (SSN) => {
  return /(2|3)[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|(1|2)[0-9]|3[0-1])(01|02|03|04|05|06|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d\d\d\d\d/g.test(SSN)
}

export const ValidateEmail = (email) => {
  const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email)
}

export const CheckArabicText = (text) =>
  /^[\u0621-\u064A0-9\s:,\\()\-_,’،.]+$/.test(text);

export const CheckEnglishText = (text) =>
  /^[a-zA-Z0-9\s:,.’\\()-_*]*$/.test(text);

export const GetPrivileges = (authorizedPages, pageNumber) => {

  return authorizedPages?.find(p => p.id === pageNumber).privileges
}

export const ValidateFieldLang = (text, type) => {
  if (Number(text) && type != 'Num')
    return false

  if (!Number(text) && type == 'Num' && text != '')
    return false

  else if (type == 'Ar' && text != '')
    return true

  else if (type == 'En' && text != '' && !CheckEnglishText(text))
    return false


  return true
}

export const FormateDate = (date, t) => {
  var timeDate = moment(date).format("YYYY/MM/DD  hh:mm A")
  return timeDate.replace('AM', t('AM')).replace('PM', t('PM'));

}
