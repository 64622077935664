import { APICall } from './index';
import fileDownload from 'js-file-download';

const ControllerName = "Role"

export const ExportRolesExcel = (filters ,lang) => {
    var nameEN = filters.find(f => f.id === "nameEN")?.value || '';
    var name = filters.find(f => f.id === "name")?.value || '';

    return APICall("get", ControllerName + "/excel?nameEN=" + nameEN + "&name=" + name,{},lang)
        .then(result => {
            return new Promise((resolve) => {
                fileDownload(result.data, 'roles.xlsx')
                resolve()
            })
        })
}

export const GetRolesLookup = (lang) => {
    return APICall("get", ControllerName + "/lookup", null, lang)
}

export const GetAllRoles = (pageSize, pageIndex, filters) => {
    var nameEN = filters.find(f => f.id === "nameEN")?.value || '';
    var name = filters.find(f => f.id === "name")?.value || '';

    return APICall("get", ControllerName + "?page=" + pageIndex + "&pageLength=" + pageSize + "&sort=Id:-1" + "&nameEN=" + nameEN + "&name=" + name)
}

export const GetRoleDetails = (roleID) => {
    return APICall("get", ControllerName + "/" + roleID)
}

export const AddRole = (RoleData) => {
    return APICall("post", ControllerName, RoleData)
}

export const UpdateRole = (roleID, RoleData) => {
    return APICall("put", ControllerName + "/" + roleID, RoleData)
}

export const DeleteRole = (roleID) => {
    return APICall("delete", ControllerName + "/" + roleID)
}
