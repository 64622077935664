import React, { useContext, useEffect } from 'react'
import CountriesState from './Charts/CountriesState'
import Doughnut from './Charts/Doughnut'
import "./style.css"
import TotalAssets from './Charts/totalAssets'
import SalesStats from './Charts/SalesStats'
import HeaderName from '../../component/HeaderName'
import { useTranslation } from 'react-i18next'
import { Context } from '../../context'


const ColorPallete = [{ id: 1, color: '#FE4D22' }, { id: 2, color: '#FFE14D' }, { id: 3, color: '#FF9A35' }, { id: 4, color: '#98A2B2' }, { id: 5, color: "#4E4E4E" }]


const HomePage = () => {


    const { t } = useTranslation()
    const { setShowDateRange } = useContext(Context)
    useEffect(() => {
        setShowDateRange(true)
        return () => setShowDateRange(false)
    }, [])

    return (
        <>
            <HeaderName PageName={t("Home")} />
            <div className='row'>
                <div className='top-charts row col-12'>
                    <CountriesState />
                    <Doughnut ColorPallete={ColorPallete} />
                </div>
                <TotalAssets />
            </div>
            <SalesStats ColorPallete={ColorPallete} />
        </>
    )
}

export default HomePage