import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Context } from '../../context'
import { useTranslation } from 'react-i18next'
import Table from '../../component/table'

import CUServiceTypes from './CUServiceTypes'
import { ExportServiceTypes, GetServiceTypes } from '../../requests'
import { GetPrivileges } from '../../utils'
import { CONTROLLER_NAMES } from '../../common/constants'

const ServiceTypes = () => {

    const [data, setData] = useState([])
    const [TotalRows, setTotalRows] = useState(0)
    const [reload, toggleReload] = useState(false)
    const [recordID, setRecordID] = useState(null)

    const ServiceTypeModal = useRef(null)
    const ServiceTypeModalUpdate = useRef(null)

    const { setLoading, authorizedPages } = useContext(Context)

    const { i18n, t } = useTranslation()

    const { canEdit, canAdd, canDelete } = GetPrivileges(authorizedPages, CONTROLLER_NAMES.ServiceCategory)

    console.log({ canEdit, canAdd, canDelete })

    const columns = useMemo(() => [
        {
            Header: t(""),
            accessor: 'name',
        },
        {
            Header: t(""),
            accessor: 'nameEN',
        },
        {
            id: 'actions',
            Header: '',
            Cell: ({ row: { original } }) =>
            (
                <> {
                    <>
                        {canEdit &&
                            <button className='update' onClick={() => { updateService(original.id) }} >
                                <i className='fas fa-edit'></i>
                            </button>}
                        {canDelete &&
                            <button className='update' onClick={() => { updateService(original.id) }} >
                                <i className='fas fa-edit'></i>
                            </button>}
                    </>
                }
                </>
            )
        }
    ], [i18n.language])

    const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
        setLoading(l => l + 1)
        GetServiceTypes(filters, pageIndex, pageSize, i18n.language).then(res => {
            setData(res.data.data)
            setTotalRows(res.data.totalCount)
            setLoading(l => l - 1)
        }).catch(err => {
            setLoading(l => l - 1)
        })

    }, [i18n.language, reload])

    const updateService = (id) => {
        setRecordID(id)
        ServiceTypeModalUpdate.current.click()
    }

    return (
        <>
            {
                canAdd &&
                <>
                    <button onClick={() => ServiceTypeModal.current.click()}> {t('Add Service')}</button>
                    <CUServiceTypes modalID={ServiceTypeModal} finishOperation={() => ServiceTypeModal.current.click()} toggleReload={toggleReload} />
                </>
            }

            <CUServiceTypes modalID={ServiceTypeModalUpdate} recordID={recordID} toggleReload={toggleReload} finishOperation={() => ServiceTypeModalUpdate.current.click()} />

            <Table
                data={data}
                columns={columns}
                TotalRows={TotalRows}
                fetchData={fetchData}
                hideActions={!canEdit}
                exportExcel={ExportServiceTypes}
            />
        </>
    )
}

export default ServiceTypes