import { useState, useContext, useCallback, useRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Context } from './../../../context/index';
import { GetAllHotels } from './../../../requests/Order';
import { FormateDate } from '../../../utils';
import Table from '../../../component/table';
import Popup from '../../../component/popup';
import { HOTEL_TYPE } from '../../../common/constants';

const modalName = 'ShowHotels';

const ShowHotels = ({ modalID, hotelId, finished, toggleReload }) => {
    const [data, setData] = useState([]);
    const [totalRows, setTotalRows] = useState(0)

    const { t, i18n } = useTranslation();
    const fetchIdRef = useRef(0)

    const column = useMemo(() => [
        {
            Header: t('ID'),
            accessor: 'id',
        },
        {
            Header: t('hotelName'),
            accessor: 'name'
        },
        {
            Header: t('Price'),
            accessor: 'price'
        },
        {
            Header: t('ExpirationDate'),
            Cell: ({ row: { original } }) => {
                return (
                    <label>{FormateDate(original?.expirationDate, t)}</label>
                )
            }

        },

        {
            Header: t('hotelCategoryValue'),
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {
                            original.hotelTypeId  == HOTEL_TYPE.Beds?
                                <span>{original.hotelCategoryValue}-{t('BEDS')}</span> :
                                <span>{original.hotelCategoryValue}-{t('STARS')}</span>
                        }
                    </>

                )
            }
        }


    ], [hotelId, i18n.language])

    const fetchData = useCallback(({ pageSize, pageIndex }) => {
        const fetchId = ++fetchIdRef.current
        if (!hotelId)
            return
        GetAllHotels(hotelId, pageSize, pageIndex, i18n.language).then(result => {
            if (fetchId === fetchIdRef.current && result.data.success) {
                setTotalRows(result.data.totalCount)
                setData(result.data.data)
                toggleReload(t => !t)
                finished()
            }
        }).catch(() => {
            toggleReload(t => !t)
        })

    }, [hotelId, i18n.language])

    return (
        <>
            <button ref={modalID} data-toggle="modal" data-target={`#${modalName + hotelId}`} hidden></button>
            <Popup name={modalName + hotelId} large={true} title={t("AvailableHotels")}
                PopupName={t("Pricikng")} t={t}>
                <Table
                    columns={column}
                    data={data}
                    fetchData={fetchData}
                    TotalRows={totalRows}
                />
            </Popup>
        </>
    )
}
export default ShowHotels;