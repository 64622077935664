import { useContext, useMemo } from "react";
import { DateRangePicker } from "rsuite"
import "rsuite/dist/rsuite.css";
import { Context } from "../../context";
import { useTranslation } from "react-i18next";
import moment from "moment";
import './style.css'

const DatePickerRange = () => {
    const { selectedDateRange, setSelectedDateRange } = useContext(Context)
    const { t, i18n } = useTranslation()

    const predefinedRanges = useMemo(
        () => [
            {
                label: t('today'),
                value: [moment()],
                placement: 'left',
                closeOverlay: false
            },
            {
                label: t('yesterday'),
                value: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                placement: 'left',
                closeOverlay: false
            },
            {
                label: t('thisWeek'),
                value: [moment().startOf('week').toDate(), moment().toDate()],
                placement: 'left',
                closeOverlay: false
            },
            {
                label: t('WeekAgo'),
                value: [moment().subtract(1, 'weeks').startOf('week').toDate(), moment().subtract(1, 'weeks').endOf('week').toDate()],
                placement: 'left',
                closeOverlay: false
            },
            {
                label: t('thisMonth'),
                value: [moment().startOf('month').toDate(), moment().toDate()],
                placement: 'left',
                closeOverlay: false
            },
            {
                label: t('monthAgo'),
                value: [moment().subtract(1, 'months').startOf('month').toDate(), moment().subtract(1, 'months').endOf('month').toDate()],
                placement: 'left',
                closeOverlay: false
            },
            {
                label: t('currentYear'),
                value: [moment().startOf('year').toDate(), moment().toDate()],
                placement: 'left',
                closeOverlay: false
            },
            {
                label: t('previousYear'),
                value: [moment().subtract(1, 'years').startOf('year').toDate(), moment().subtract(1, 'years').endOf('year').toDate()],
                placement: 'left',
                closeOverlay: false
            },
            {
                label: t('allDuration'),
                closeOverlay: false,
                value: [moment('2024-08-01').toDate(), moment().toDate()],
                placement: 'left',
            },

        ], [i18n.language])



    const handleDateRange = (dates) => {
        setSelectedDateRange([moment(dates[0]).format('YYYY-MM-DD'), moment(dates[1]).format('YYYY-MM-DD')])
    }

    return (
        <div className="DatePickerRange">
            <DateRangePicker
                onChange={handleDateRange}
                value={[moment(selectedDateRange[0]).toDate(), moment(selectedDateRange[1]).toDate()]}
                onOk={handleDateRange}
                ranges={predefinedRanges}
                placement={'autoHorizontalStart'}
                cleanable={false}
                formate="yyyy-MM-dd"
            />
        </div>
    )
}

export default DatePickerRange