import React, { useState, useContext, useEffect, useRef, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Context } from '../../context';
import { useTranslation } from "react-i18next";
import { Login, Recaptchahandler } from '../../requests';
import { toast } from 'react-toastify';
import { ValidatePassword } from '../../utils';
import ReCAPTCHA from 'react-google-recaptcha';
import './style.css';
import Field from '../../component/field';

const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY

const LoginC = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const { setIsLoggedIn } = useContext(Context)
    const { t, i18n } = useTranslation()
    const { state } = useLocation();
    const fetchIdRef = useRef(0)

    const captchaRef = useRef(null);
    const [isHuman, setIsHuman] = useState(false);

    useEffect(() => {
        if (fetchIdRef.current === 0 && state) {
            toast.success(t(state.verifyMessage))
            fetchIdRef.current = 1
        }
    }, [])

    const login = (e) => {
        e.preventDefault()
        if (!ValidatePassword(password)) {
            toast.error(t("wrongPassword"))
            return
        }

        setLoading(true)
        let userLogInfo = {
            Email: email,
            Password: password
        }
        Login(userLogInfo, i18n.language).then(result => {
            setLoading(false)
            if (result.data.success) {
                localStorage.setItem('ut', result.data.data.accessToken)
                localStorage.setItem('rt', result.data.data.refreshToken)

                setIsLoggedIn(true)
            }
            else
                toast.error(t("wrongLoginCredentails"))
        }).catch(() => {
            setLoading(false)
        })
    }

    const recaptchahandler = () => {
        const RecaptchaData = {
            token: captchaRef.current.getValue(),
        }

        Recaptchahandler(RecaptchaData).then(data => {
            setIsHuman(data.data.data)
        })
    }

    return (
        <>
            <div className={`login_wrapper ${i18n.language}`} >
                <div className='Form_container'>
                    <form className="login_content">
                        <div>
                            <img className='formLogo' src='./images/Logo.png?v=1.00' />
                            <div>
                                <span className='caption'>
                                    {t('Login_Caption')}
                                </span>
                                <span className='loginTitle d-block'>{t("loginTitle")}</span>
                            </div>

                        </div>
                        <div className='inputsContainer'>
                            <div className='input-container'>
                                <Field value={email} setValue={setEmail} placeholder={t("Email")} label={t("Email")} type="text" name="email" />
                            </div>
                            <div className='input-container'>
                                <Field type="password" name="password" value={password} setValue={setPassword} label={t("password")} placeholder={t("password")} />
                            </div>
                        </div>
                        <div className='forgetPasswordContainer'>
                            <div className='rememberMeContainer'>
                                <input type="checkbox" id="rememberMe" className='rememberMeCheckbox' />
                                <label htmlFor="rememberMe" className='rememberMeLabel'>{t("rememberMe")}</label>
                            </div>
                            <Link to='/ForgetPassword' className='forgetPasswordLink'>{t("forgetPassword")}</Link>

                        </div>

                        <div className='recaptchaContainer'>
                            <ReCAPTCHA className='recaptcha' sitekey={recaptchaSiteKey} ref={captchaRef} onChange={recaptchahandler} />
                        </div>

                        <button className="login-btn" onClick={login} disabled={loading || !isHuman}>
                            <span className={loading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                            {t("login")}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default memo(LoginC);

