import {APICall} from './index';

const ControllerName = "page"

export const GetAuthorizedPages = () => {
    return APICall("get", ControllerName + "/relatedLookup")
}

export const GetPagesLookup = () => {
    return APICall("get", ControllerName + "/lookup")
}