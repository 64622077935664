import { APICall } from './index';

const ControllerName = "Transportation"



export const GetAllTransportation = (filters = [], pageSize, pageIndex, lang) => {

    var visitorName = filters.find(f => f.id === "visitorName")?.value || '';

    return APICall("get", ControllerName + "?page=" + pageIndex + "&pageLength=" + pageSize + "&sort=Id:-1&visitorName=" + visitorName, {}, lang)
}