import React, { useState, memo,useRef } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { ValidatePassword } from '../../utils';
import { ResetPassword,Recaptchahandler } from '../../requests'
import Field from '../../component/field';
import ReCAPTCHA from 'react-google-recaptcha';
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY

const ForgetPasswordC = () => {
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation()
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const captchaRef = useRef(null);
    const [isHuman, setIsHuman] = useState(false);

    const recaptchahandler = () => {
        const RecaptchaData = {
            token: captchaRef.current.getValue(),
        }

        Recaptchahandler(RecaptchaData).then(data => {
            setIsHuman(data.data.data)
        })
    }

    const resetPassword = (e) => {
        e.preventDefault()

        if (!ValidatePassword(password)) {
            toast.error(t("wrongPassword"))
            return
        }

        if (password !== confirmPassword) {
            toast.error(t("password not matched"))
            return
        }

        var ResetPasswordData = {
            code: searchParams.get("code"),
            password: password
        }

        setLoading(true)
        ResetPassword(ResetPasswordData).then(result => {
            setLoading(false)
            if (result.data.success)
                navigate('/login', { state: { verifyMessage: "Password updated successfully" } })

        }).catch(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <div className={`login_wrapper ${i18n.language}`}>
            <div className='Form_container'>
            <form className="login_content">
                    <div>
                        <img className='formLogo' src='./images/logo.png' />
                        <div>
                            <span className='loginTitle d-block'>{t("ResetPassword")}</span>
                        </div>  
                      
                        </div>
                        
                        <div className='input-container'>
                                <Field type="password" name="password" value={password} setValue={setPassword} label={t("password")} placeholder={t("password")} />
                        </div>

                        <div className='input-container'>
                                <Field type="password" name="confirmPassword" value={confirmPassword} setValue={setconfirmPassword} label={t("confirmNewPassword")} placeholder={t("confirmNewPassword")} />
                        </div>
                        
                        <div className='recaptchaContainer'>
                            <ReCAPTCHA className='recaptcha' sitekey={recaptchaSiteKey} ref={captchaRef} onChange={recaptchahandler} />
                        </div>

                        <button className="login-btn" onClick={resetPassword} disabled={loading || !isHuman}>
                            <span className={loading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                            {t("confirm")}
                        </button>
                        <div className='donotHaveAccountContainer'>
                             {t("backTo")} <Link to='/'>{t("login")}</Link>
                            
                        </div>

                </form>
            </div>
            </div>
        </>
    );
}

export default memo(ForgetPasswordC);

