import { APICall } from './index';

const ControllerName = "Home"

export const GetRequestTotals = (startDate, endDate, lang) => {
    return APICall("get", ControllerName + `/RequestsByServiceTypeTotals?StartDate=${startDate}&EndDate=${endDate}`, {}, lang)
}

export const GetRequestTotalsByCountry = (startDate, endDate, lang) => {
    return APICall("get", ControllerName + `/RequestsByNationalityTotals?StartDate=${startDate}&EndDate=${endDate}`, {}, lang)
}

export const GetTotalProviders = (startDate, endDate) => {
    return APICall("get", ControllerName + `/ProviderTotals?StartDate=${startDate}&EndDate=${endDate}`)
}

export const GetTotalUsers = (startDate, endDate) => {
    return APICall("get", ControllerName + `/UsersTotals?StartDate=${startDate}&EndDate=${endDate}`)
}

export const GetTotalSales = (startDate, endDate, lang) => {
    return APICall("get", ControllerName + `/SalesTotals?StartDate=${startDate}&EndDate=${endDate}`, {}, lang)
}

export const GetSalesDetails = (startDate, endDate) => {
    return APICall("get", ControllerName + `/SalesDataDetails?StartDate=${startDate}&EndDate=${endDate}`)
}
