import axios from 'axios';
import jwt_decode from "jwt-decode";
import { toast } from 'react-toastify'

const API_URL = window.API_URL + 'api/'


const expirationMargin = 5 * 60 * 1000
let refreshingTokenNow = false

export const APICall = async (method, API, params, lang) => {
    let now = new Date()
    now.setTime(now.getTime() - expirationMargin)

    while (refreshingTokenNow)
        await new Promise(resolve => setTimeout(resolve, 1000));// hold other calls until token is refreshed

    let token = null
    if (localStorage.getItem("ut"))
        token = jwt_decode(localStorage.getItem("ut"))

    if (token && new Date(token.exp * 1000) < now) {
        refreshingTokenNow = true

        await axios.post(API_URL + "auth/refresh", { accessToken: localStorage.getItem("ut"), refreshToken: localStorage.getItem("rt") }).then(result => {
            localStorage.setItem('ut', result.data.data.accessToken)
            localStorage.setItem('rt', result.data.data.refreshToken)

        })
            .catch(error => {
                if (error.response?.status === 403) {
                    forceLogout()
                    return
                }
            })

        refreshingTokenNow = false
    }

    return new Promise((resolve, reject) => {
        axios({
            baseURL: API_URL,
            method,
            url: API + (lang ? (API.includes('?') ? '&' : '?') + 'culture=' + lang : ''),
            data: params || null,
            responseType: API.includes('/excel') ? 'blob' : 'json',
            headers: { 'Authorization': "Bearer " + localStorage.getItem("ut") }
        }).then(response => {
            resolve(response)
        }).catch(error => {
            if (error.response?.status === 403) {
                forceLogout()
                return
            }

            toast.error(error.response?.data.message || error.message)

            console.log(error)
            reject(error)
        })
    })
}

const forceLogout = () => {
    localStorage.clear()
    window.location.reload()
}

export * from './page';
export * from './role';
export * from './user';
export * from './RolePriviledges';
export * from './google';
export * from './MedicalPendingOrder';
export * from './serviceTypes'
export * from './ServiceProviders'
export * from './ServiceCategory'
export * from './Hotels'
export * from './Home'
export * from './Transactions'
export * from './Governments'
export * from './TeleConsultation'