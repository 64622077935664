import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Context } from '../../context'
import { useTranslation } from 'react-i18next'
import Table from '../../component/table'

import { DeleteHospitals, GetHospitals } from '../../requests'
import { GetPrivileges } from '../../utils'
import { CONTROLLER_NAMES, Paths } from '../../common/constants'
import CUHospitals from './CUHospitals'
import { ConfirmAlert } from '../../component/confirmAlert'
import ColumnFilter from '../../component/table/ColumnFilter'
import { toast } from 'react-toastify'

const ServiceProvider = () => {

    const [data, setData] = useState([])
    const [TotalRows, setTotalRows] = useState(0)
    const [reload, toggleReload] = useState(false)
    const [recordID, setRecordID] = useState(null)
    const [toggleDesc, setToggleDesc] = useState(false)
    const [toggleDescEn, setToggleDescEn] = useState(false)
    const ServiceProviderModal = useRef(null)
    const ServiceProviderModalUpdate = useRef(null)

    const { setLoading, authorizedPages } = useContext(Context)

    const { i18n, t } = useTranslation()

    const { canEdit, canAdd, canDelete } = GetPrivileges(authorizedPages, CONTROLLER_NAMES.Hospital)


    const columns = useMemo(() => [
        {
            Header: t("ID"),
            accessor: 'id',
        },
        {
            Header: t("Name"),
            accessor: 'name',
            Filter: ColumnFilter

        },
        {
            Header: t("English Name"),
            accessor: 'nameEN',
            Filter: ColumnFilter

        },
        {
            Header: t("description"),
            accessor: 'description',
            Cell: ({ row: { original } }) =>
            (
                <div>
                    {original.description.substring(0, toggleDesc != original.id ? 50 : original.description.length)}
                    {toggleDesc != original.id ?
                        <span className='more' onClick={(e) => setToggleDesc(original.id)}>
                            {t('ShowMore')}
                        </span>
                        :
                        <span className='less' onClick={(e) => setToggleDesc(null)}>
                            {t("ShowLess")}
                        </span>}
                </div>
            )
        },
        {
            Header: t("descriptionEN"),
            accessor: 'descriptionEN',
            Cell: ({ row: { original } }) =>
            (
                <div>
                    {original.descriptionEN.substring(0, toggleDescEn != original.id ? 50 : original.description.length)}
                    {toggleDescEn != original.id ?
                        <span className='more' onClick={(e) => setToggleDescEn(original.id)}>
                            {t('ShowMore')}
                        </span>
                        :
                        <span className='less' onClick={(e) => setToggleDescEn(null)}>
                            {t("ShowLess")}
                        </span>}
                </div>

            )
        },
        {
            Header: t("Image"),
            Cell: ({ row: { original } }) =>
            (
                original.image ? <a href={Paths.DashboardServer + original.image} target='_blank' >{t("Click to Open Img")}</a> : ''

            )

        },
        {
            id: 'actions',
            Cell: ({ row: { original } }) =>
            (
                <>
                    {canEdit &&
                        <button className='update' onClick={() => updateHospital(original.id)} >
                            <i className='fas fa-edit'></i>
                        </button>}
                    {/* {canDelete &&
                        <button className='delete' onClick={() => handleDelete(original.id)} >
                            <i className='fas fa-trash'></i>
                        </button>} */}
                </>
            )
        }
    ], [i18n.language, toggleDesc, toggleDescEn])

    const fetchData = useCallback(({ pageIndex, pageSize, filters }) => {
        setLoading(l => l + 1)
        GetHospitals(filters, pageIndex, pageSize, i18n.language).then(res => {
            setData(res.data.data)
            setTotalRows(res.data.totalCount)
            setLoading(l => l - 1)
        }).catch(err => {
            setLoading(l => l - 1)
        })

    }, [i18n.language, reload])

    const updateHospital = (id) => {
        setRecordID(id)
        ServiceProviderModalUpdate.current.click()
    }

    const handleDelete = (ID) => {


        ConfirmAlert(t('delete Hospital'),
            'Delete This Hospital?', () => {

                setLoading(l => l + 1)
                DeleteHospitals(ID).then(res => {
                    if (res.data.success) {
                        setLoading(l => l - 1)
                        toggleReload(e => !e)
                        toast.success(t("hospital-deleted-successfully"))
                    }
                }).catch(err => {
                    setLoading(l => l - 1)
                })
            }, t
        )
    }

    return (
        <>
            {
                canAdd &&
                <>
                    <button className='ActionBtn' onClick={() => ServiceProviderModal.current.click()}> {t('Add Hospital')}</button>
                    <CUHospitals modalID={ServiceProviderModal} finishOperation={() => ServiceProviderModal.current.click()} toggleReload={toggleReload} />
                </>
            }

            {canEdit && <>
                <CUHospitals modalID={ServiceProviderModalUpdate} RecordID={recordID} toggleReload={toggleReload} finishOperation={() => ServiceProviderModalUpdate.current.click()} />
            </>}

            <Table
                data={data}
                columns={columns}
                TotalRows={TotalRows}
                fetchData={fetchData}
                hideActions={!canEdit}
            />
        </>
    )
}

export default ServiceProvider