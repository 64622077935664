import { APICall } from ".";

const ControllerName = "ServiceType"


export const GetServiceTypeLookUp = (lang) => {
    return APICall('get', `${ControllerName}/lookup`, {},lang)
}

export const ExportServiceTypes = (filters, pageIndex, pageSize, lang) => {
    const name = filters?.find(ele => ele.id == 'name')?.value || ''
    const nameEn = filters?.find(ele => ele.id == 'nameEn')?.value || ''
    return APICall('get', `${ControllerName}/excel?name=${name}&nameEN=${nameEn}&page=${pageIndex}&pageLength=${pageSize}&sort=Id:-1`, {}, lang)
}

export const GetServiceTypes = (filters, pageIndex, pageSize, lang) => {
    const name = filters?.find(ele => ele.id == 'name')?.value || ''
    const nameEn = filters?.find(ele => ele.id == 'nameEn')?.value || ''
    return APICall('get', `${ControllerName}?name=${name}&nameEN=${nameEn}&page=${pageIndex}&pageLength=${pageSize}&sort=Id:-1`, {}, lang)
}

export const GetServiceTypeById = (Id,lang) => {
    return APICall('get', `${ControllerName}/${Id}`, {},lang)
}

export const AddServiceType = (Id ,ServiceTypeObj,lang) => {
    return APICall('post', `${ControllerName}/${Id}`,ServiceTypeObj, lang)
}

export const UpdateServiceType = (Id ,ServiceTypeObj,lang) => {
    return APICall('put', `${ControllerName}/${Id}`,ServiceTypeObj, lang)
}

export const DeleteServiceType = (Id,lang ) => {
    return APICall('delete', `${ControllerName}/${Id}`,{}, lang)
}