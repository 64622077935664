import React from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Icon } from '@iconify/react';
import "./styles.css"
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const DatePickerC = ({ minDate, disabled, className, Apply, dates, isSingle, placeholder = "", label = '' }) => {
  const { t } = useTranslation()

  return (
    < div className={`DatePickerContainer ${className}`}>
      <label>{t(`${label}`)}</label>
      <div className='DatePicker'>
        <DatePicker
          showIcon
          icon={<Icon icon="ci:calendar-days" className='DateIcon' />}
          className="dateInput"
          selectsRange={!isSingle}
          placeholderText={placeholder}
          startDate={!isSingle && dates?.from}
          endDate={!isSingle && dates?.to}
          selected={isSingle && dates}
          showYearDropdown
          minDate={minDate}
          yearDropdownItemNumber={50}
          scrollableYearDropdown
          disabled={disabled}
          onChange={(d) => {
            Apply((isSingle) ? d : { from: d[0], to: d[1] })
          }}
          dateFormat="dd/MM/yyyy"
        />
      </div>
    </div >
  )
}
export default DatePickerC