import { APICall } from './index';

const ControllerName = "Hotels"

export const GetAllHotelsData = (filters, pageIndex, pageSize, lang) => {
    let name = filters?.find(ele => ele.id == 'visitorName')?.value || ''

    return APICall("get", ControllerName + "/allRequestedHotels?visitorName=" + name + "&page=" + pageIndex + "&pageLength=" + pageSize + "&sort=Id:-1", {}, lang)
}

export const GetAllHotelsOrdersData = (filters, pageIndex, pageSize, lang) => {
    let name = filters?.find(ele => ele.id == 'visitorName')?.value || ''
    return APICall("get", ControllerName + "/allRequestedHotelsOrders?visitorName=" + name + "&page=" + pageIndex + "&pageLength=" + pageSize + "&sort=Id:-1", {}, lang)
}

export const GetHotelDetails = (id, lang) => {
    return APICall("get", ControllerName + '?orderId=' + id + '&sort=Id:-1', {}, lang) //to be removed
}
export const AddUserHotels = (formData, lang) => {
    return APICall("post", ControllerName + `/addAllHotels`, formData, lang)
}
export const GetOrderDetails = (id, lang) => {
    return APICall("get", ControllerName + `/GetRequestCategories?HotelPendingOrderId=${id}`, lang)
}