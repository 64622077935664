import Select from 'react-select';
import './style.css';
import { useTranslation } from 'react-i18next';

const MultiSelect = ({ id, label, setInputValue, options, isClearable = true, containerClassName, selectedOptions, placeholder ='...Select', setSelectedOptions, isMulti = true, isDisabled = false, className }) => {
    const {t} =useTranslation()
    return <div className={`row form-group ${containerClassName || 'col-md-6'} MultiSelect`} >
        {
            label &&
            <label className="col-sm-12 Defaultlabel" htmlFor={id}>{label}</label>
        }
        <Select
            className={`col-sm-12 multi-select ${className}`}
            options={options}
            isMulti={isMulti}
            isSearchable
            onChange={setSelectedOptions}
            onInputChange={(e) => setInputValue ? setInputValue(e) : undefined}
            value={selectedOptions}
            placeholder={t(`${placeholder}`)}
            isClearable={isClearable}
            isDisabled={isDisabled}
        />
    </div>
}
export default MultiSelect;