import React, { useCallback, useContext, useMemo, useRef, useState } from 'react'
import { Context } from '../../../context'
import { useTranslation } from 'react-i18next'
import Table from '../../../component/table'
import ColumnFilter from '../../../component/table/ColumnFilter'
import { GetAllTours } from '../../../requests/Tour';
import { FormateDate } from '../../../utils';
const TourOrders = () => {

    const [data, setData] = useState([])
    const [reload, toggleReload] = useState(false)
    const [totalRows, setTotalRows] = useState(0)

    const fetchIdRef = useRef(0)

    const { setLoading } = useContext(Context)

    const { t, i18n } = useTranslation()

    const columns = useMemo(() => [
        {
            Header: t('ID'),
            accessor: 'orderId',
        },
        {
            Header: t('Status'),
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {
                            <div className={`boxstatus status-order${original.statusId}`}>
                                <span className={`textstatus status${original.statusId}`}>{original.status}</span>
                            </div >
                        }
                    </>
                )
            }
        },
        {
            Header: t('orderDate'),
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {
                            original.date ?
                                FormateDate(original.date, t)
                                : "-"
                        }
                    </>
                )
            }
        },
        {
            Header: t('Name'),
            accessor: 'visitorName',
            Filter: ColumnFilter
        },
        {
            Header: t('Price'),
            accessor: 'paidPrice'
        },


    ], [i18n.language])

    const fetchData = useCallback(({ pageSize, pageIndex, filters }) => {
        const fetchId = ++fetchIdRef.current

        setLoading(l => l + 1)
        GetAllTours(filters, pageSize, pageIndex, i18n.language).then(result => {
            setLoading(l => l - 1)
            if (fetchId === fetchIdRef.current && result.data.success) {
                setData(result.data.data)
                setTotalRows(result.data.totalCount)
            }
        }).catch(() => {
            setLoading(l => l - 1)
        })

    }, [reload, i18n.language])

    return (
        <div className='Clearance-TableContainer'>
            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                TotalRows={totalRows}
            />
        </div>
    )
}
export default TourOrders