import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Popup from '../../../component/popup'
import { Context } from '../../../context'
import { GetMedicalPendingOrderById, AddHospitalPrices } from '../../../requests'
import Field from '../../../component/field'
import FileInput from "../../../component/FileInput"
import { toast } from 'react-toastify';
import PriceInput from './PriceInput'
import { ConfirmAlert } from '../../../component/confirmAlert'
import { ValidatePdf } from '../../../utils'

const modalName = 'AddPricing'

const AddPricing = ({ modalID, RecordID,setRecordId, finishOperation, reload, isRead, toggleReload }) => {
    const { t } = useTranslation()
    const [hospitalData, setHospitalData] = useState([])
    const [HospitalsPricing, setHospitalsPricing] = useState([])
    const { loading, setLoading } = useContext(Context)

    const [fileData, setFileData] = useState(null)


    useEffect(() => {
        if (!RecordID)
            return

        setLoading(l => l + 1)
        GetMedicalPendingOrderById(RecordID).then(res => {
            setLoading(l => l - 1)
            setHospitalData(res.data.data)
            setHospitalsPricing([])
        }).catch(err => {
            setLoading(l => l - 1)
        })

    }, [RecordID])


    const handleSave = () => {
        let isValid = true
        HospitalsPricing.forEach(function (ele) {
            if (!Number(ele.HospitalPrice)) {
                isValid = false
                return;
            }
        });

        if (!isValid) {
            toast.error(t('Please Enter Correct Prices'))
            return
        }



        if (!ValidatePdf(fileData)) {
            toast.error(t('Please Upload PDF File'))
            return

        }

        ConfirmAlert(t("Add Pricing"),
            "Add Pricing?",
            () => {
                let dataForm = new FormData()
                let prices = HospitalsPricing.map(ele => ({ id: ele.HospitalId, price: ele.HospitalPrice }))

                dataForm.append("DescriptionPDF", fileData)
                dataForm.append("HospitalsPrices", JSON.stringify(prices))

                setLoading(l => l + 1);

                AddHospitalPrices(RecordID, dataForm).then(result => {
                    toast.success(t("Prices Added Successfully"));
                    finishOperation();
                    toggleReload(e => !e)
                    resetFields()
                    setLoading(l => l - 1);
                }).catch(() => {
                    setLoading(l => l - 1);
                })
            }, t)
    }

    const resetFields = () => {
        setFileData(null)
        setHospitalData([])
        setRecordId(null)
        setHospitalsPricing([])
    }

    return (
        <>
            <button ref={modalID} data-toggle="modal" data-target={`#${modalName + RecordID}`} hidden></button>
            <Popup name={modalName + RecordID} large={true} title={!isRead ? t("Add Pricing") : t('Details')}
                PopupName={t("Pricing")} t={t} onClose={() => setFileData(null)}>

                {
                    hospitalData.map((ele, index) => (
                        <Fragment key={index}>
                            <PriceInput medicalPrice={ele.price} reload={reload} isRead={isRead} Index={index} label={ele.label} Id={ele.value} HospitalsPricing={HospitalsPricing} setHospitalsPricing={setHospitalsPricing} />
                        </Fragment>
                    ))
                }

                <hr />
                {!isRead &&
                    <>
                        <FileInput accept={'.pdf'} setValue={setFileData} label={t('Add PDF')} value={fileData} />
                        <div className="buttons update">
                            <button className="ActionBtn  my-2 my-sm-0" onClick={handleSave} disabled={loading}>
                                {t("Save")}
                            </button>
                        </div>
                    </>
                }
            </Popup>
        </>
    )
}

export default AddPricing