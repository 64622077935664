import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './style.css'

export const ConfirmAlert = (title, message, onConfirm, t, onClose) =>
    confirmAlert({
        title,
        message: t('Are you sure that you want to ' + message),
        buttons: [
            {
                label: t('Yes'),
                onClick: () => onConfirm()
            },
            {
                label: t('No'),
                onClick: () => onClose ? onClose() : undefined
            }
        ],
        onClickOutside: () => onClose ? onClose() : undefined,
    })