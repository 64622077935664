import React, { useState, useContext, memo } from 'react';
import { useTranslation } from "react-i18next";
import { ChangePassword, Logout } from '../../requests';
import { toast } from 'react-toastify';
import { Context } from '../../context';
import { ValidatePassword } from '../../utils';
import './style.css';

const timeout = 2000

const ChangePasswordC = () => {
    const [newPassword, setnewPassword] = useState('')
    const [currentPassword, setcurrentPassword] = useState('')
    const [confirmNewPassword, setconfirmNewPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPassword, setshowPassword] = useState(false)
    const [shownewPassword, setshownewPassword] = useState(false)
    const [showconfirmNewPassword, setshowconfirmNewPassword] = useState(false)
    const { setIsLoggedIn } = useContext(Context)

    const { t } = useTranslation()

    const logout = () => {
        Logout().then(result => {
            if (result.data.success) {
                localStorage.clear()
                setIsLoggedIn(false)
            }
        })
    }

    const changePassword = () => {
        if (currentPassword.trim() === '') {
            toast.error(t("Please fill all fields"))
            return
        }

        if (!ValidatePassword(newPassword)) {
            toast.error(t("wrongPassword"))
            return
        }

        if (newPassword !== confirmNewPassword) {
            toast.error(t("password not matched"))
            return
        }

        const PasswordData = {
            OldPassword: currentPassword,
            NewPassword: newPassword
        }

        setLoading(true)
        ChangePassword(PasswordData).then(result => {
            setLoading(false)
            if (result.data.data === "Success") {
                toast.success(t('Password updated successfully'))
                resetAllFields()

                setTimeout(() => {
                    logout();
                }, timeout);
            }

        }).catch(() => {
            setLoading(false)
        })
    }

    const resetAllFields = () => {
        setcurrentPassword('')
        setnewPassword('')
        setconfirmNewPassword('')
    }

    return (
        <div className='changePasswordDiv'>
            <div className='title'>
                {t('ChangePassword')}
            </div>

            <label htmlFor="currentPassword">{t("currentPassword")}</label>
            <div className='inputDiv'>
                <input className='input' type={showPassword ? "text" : "password"} id="currentPassword" value={currentPassword} onChange={(e) => setcurrentPassword(e.target.value)}></input>
                <span className='icon' onClick={() => setshowPassword(!showPassword)}><i className={!showPassword ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}></i></span>
            </div>

            <label htmlFor="newPassword">{t("newPassword")}</label>
            <div className='inputDiv'>
                <input className='input' type={shownewPassword ? "text" : "password"} id="newPassword" value={newPassword} onChange={(e) => setnewPassword(e.target.value)}></input>
                <span className='icon' onClick={() => setshownewPassword(!shownewPassword)}><i className={!shownewPassword ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}></i></span>
            </div>

            <label htmlFor="confirmNewPassword">{t("confirmNewPassword")}</label>
            <div className='inputDiv'>
                <input className='input' type={showconfirmNewPassword ? "text" : "password"} id="confirmNewPassword" value={confirmNewPassword} onChange={(e) => setconfirmNewPassword(e.target.value)}></input>
                <span className='icon' onClick={() => setshowconfirmNewPassword(!showconfirmNewPassword)}><i className={!showconfirmNewPassword ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}></i></span>
            </div>

            <button className="button" onClick={changePassword} disabled={loading}>
                <span className={loading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                {t("Save")}
            </button>
        </div>
    );
}

export default memo(ChangePasswordC);

