import fileDownload from "js-file-download";
import { APICall } from ".";

const ControllerName = "ServiceCategory"


export const serviceCatgoryLookUp = (lang) => {
    return APICall('get', `${ControllerName}/lookup`, {}, lang)
}

export const ExportServiceCategory = (filters, lang) => {
    const name = filters?.find(ele => ele.id == 'name')?.value || ''
    const nameEn = filters?.find(ele => ele.id == 'nameEn')?.value || ''
    return APICall('get', `${ControllerName}/excel?name=${name}&nameEN=${nameEn}`, {}, lang)
    .then(result => {
        return new Promise((resolve) => {
            fileDownload(result.data, 'Service Categories.xlsx')
            resolve()
        })
    });
}

export const GetserviceCatgory = (filters, pageIndex, pageSize, lang) => {
    const name = filters?.find(ele => ele.id == 'name')?.value || ''
    const nameEn = filters?.find(ele => ele.id == 'nameEN')?.value || ''
    return APICall('get', `${ControllerName}?name=${name}&nameEN=${nameEn}&page=${pageIndex}&pageLength=${pageSize}&sort=Id:-1`, {}, lang)
}

export const GetserviceCatgoryById = (Id,lang) => {
    return APICall('get', `${ControllerName}/${Id}`, {},lang)
}

export const AddServiceCategory = (HospitalsObj,lang) => {
    return APICall('post', `${ControllerName}/`,HospitalsObj, lang)
}

export const updateServiceCategory = (Id ,HospitalsObj,lang) => {
    return APICall('put', `${ControllerName}/${Id}`,HospitalsObj, lang)
}

export const DeleteServiceCategory = (Id,lang ) => {
    return APICall('delete', `${ControllerName}/${Id}`,{}, lang)
}