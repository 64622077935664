import React, { useContext } from 'react'
import Popup from '../../component/popup'
import { Context } from '../../context'
import { useTranslation } from 'react-i18next'

const modalName = 'ServiceTypeOp'

const CUServiceTypes = (RecordID, finishOperation, toggleReload, modalID) => {

    const { loading, setLoading } = useContext(Context)
    const { t } = useTranslation()
    const saveService = () => {

        finishOperation()
        toggleReload()
    }

    return (
        <>
            <button ref={modalID} data-toggle="modal" data-target={`#${modalName + RecordID}`} hidden></button>
            <Popup name={modalName + RecordID} large={true} title={RecordID ? t("Edit Service") : t("Add Service")}
                PopupName={t("Role")} t={t}>
                <div className='row'>
                </div>
                <div className="buttons">
                    <button className="btn ActionBtn my-2 my-sm-0" onClick={saveService} disabled={loading}>
                        {RecordID ? t("Update") : t("Save")}
                    </button>
                </div>
            </Popup>
        </>
    )
}

export default CUServiceTypes