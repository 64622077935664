import React, { useState, useEffect, memo } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { VerifyAccount } from '../../requests'

const VerifyAccountC = () => {
    const [showLogin, setshowLogin] = useState(false);
    const [searchParams] = useSearchParams();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        let code = searchParams.get("code");

        VerifyAccount(code).then(result => {
            if (result.data.success)
                setshowLogin(true);
        })
    }, [searchParams])

    return (
        <>
            <div className={`login_wrapper ${i18n.language}`}>
                <form className="login_content verifyAccount">
                    <div className="separator"></div>

                    <div className='message' >
                        <img className='checkimg' src='images/check.png' width="20" height="20" alt="logo" />
                        <div>{t("Your account is verified successfully")}</div>
                    </div>

                    {
                        showLogin && (
                            <button className="btn btn-light" >
                                <span role="status" aria-hidden="true"></span>
                                <Link to="/login" >{t("login")}</Link>
                            </button>
                        )
                    }

                    <div className="clearfix"></div>

                    <div className="separator"></div>
                </form>
            </div>
        </>
    );
}

export default memo(VerifyAccountC);

