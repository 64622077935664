import React, { useState, memo,useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { ValidateEmail } from '../../utils';
import { ForgetPassword ,Recaptchahandler} from '../../requests'
import Field from '../../component/field';
import ReCAPTCHA from 'react-google-recaptcha';
const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITEKEY
const ForgetPasswordC = () => {
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const { t, i18n } = useTranslation()
    const captchaRef = useRef(null);
    const [isHuman, setIsHuman] = useState(false);

    const recaptchahandler = () => {
        const RecaptchaData = {
            token: captchaRef.current.getValue(),
        }

        Recaptchahandler(RecaptchaData).then(data => {
            setIsHuman(data.data.data)
        })
    }


    const forgetPassword = (e) => {
        e.preventDefault()

        if (!ValidateEmail(email)) {
            toast.error(t("Email is not valid"))
            return
        }

        setLoading(true)
        ForgetPassword(email).then(result => {
            setLoading(false)
            if (result.data.data === "success")
                 toast.success(t("Code is sent to your email"))
        }).catch(() => {
            setLoading(false)
        })
    }

    return (
        <>
            <div className={`login_wrapper ${i18n.language}`}>
            <div className='Form_container'>
            <form className="login_content">
                    <div>
                        <img className='formLogo' src='./images/logo.png' />
                        <div>
                            <span className='loginTitle d-block'>{t("Send Code")}</span>
                        </div>  
                      
                        </div>
                        <div className='inputsContainer'>
                            <div className='input-container'>
                                <Field value={email} setValue={setEmail} placeholder={t("Email")} label={t("Email")} type="text" name="email" />
                            </div>
                        </div>
                        <div className='recaptchaContainer'>
                            <ReCAPTCHA className='recaptcha' sitekey={recaptchaSiteKey} ref={captchaRef} onChange={recaptchahandler} />
                        </div>

                        <button className="login-btn" onClick={forgetPassword} disabled={loading || !isHuman}>
                            <span className={loading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                            {t("Send Code")}
                        </button>
                        <div className='donotHaveAccountContainer'>
                             {t("backTo")} <Link to='/'>{t("login")}</Link>
                            
                        </div>

                </form>
            </div>

            </div>
        </>
    );
}

export default memo(ForgetPasswordC);

