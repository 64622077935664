import React, { useContext, useEffect, useState } from 'react'
import { Context } from "../../../context"
import { useTranslation } from 'react-i18next'
import { GetTotalSales } from '../../../requests'
import ChartHeader from './ChartHeader'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react/dist/iconify.js'
import LineChart from './LineChart'


const SalesStats = ({ ColorPallete }) => {

    const [data, setData] = useState([])

    const { setLoading ,selectedDateRange} = useContext(Context)

    const { i18n, t } = useTranslation()

    useEffect(() => {
        setLoading(l => l + 1)
        GetTotalSales(selectedDateRange[0] ,selectedDateRange[1],i18n.language).then(res => {
            setData(res.data.data)
            setLoading(l => l - 1)
        }).catch(err => {
            setLoading(l => l - 1)
        })
    }, [i18n.language,selectedDateRange])

    return (

        <div className="sales">
            <ChartHeader Name={t('TotalSales') + ' ( ' + data.totalSales + "  " + t("EGP") + ' )'}>
                <div className="NavigateTo">
                    <Link to="/transactions">
                        <Icon icon='solar:alt-arrow-left-line-duotone' className="NavigationToIcon" />
                    </Link>
                </div>
            </ChartHeader>


            <div className="type-sale row">
                <div className="type-sales col-md-12 col-lg-4">
                    <div className="type-order">
                        {
                            data?.typeSales?.map((ele, index) => (
                                <div className="order" key={ele.id}>
                                    <div className="RequestName">
                                        <div className="Bullet-color" style={{ backgroundColor: ColorPallete.find(o => o.id == ele.id).color }}>
                                        </div>
                                        <span>
                                            {t("RequestsText")} {ele.type}
                                        </span>
                                    </div>
                                    <div className="orderTypeTotal">
                                        <span>{ele.sales}</span>
                                        <span>{t("EGP")}</span>
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="graph col-md-12 col-lg-8"> <LineChart ColorPallete={ColorPallete} Lines={data?.typeSales || []} /></div>
            </div>
        </div>

    )
}

export default SalesStats