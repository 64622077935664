import React from "react";
import { HashRouter as Router } from "react-router-dom";
import Provider from "../context";
import Routes from "./routes";
import { ToastContainer } from 'react-toastify';
import Spinner from "../component/spinner";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css';

const App = () => {
  return (
    <Provider>
      <Router>
        <Routes />
      </Router>
      <Tooltip id="my-tooltip" />
      <ToastContainer />
      <Spinner />
    </Provider>
  );
};

export default App;
