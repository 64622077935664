import React, { useState, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import HotelOrders from "./HotelOrders"
import FollowRequests from "./Medical"
import { GetRequestsCounts } from '../../requests/Order'
import TransactionOrders from './TransportationOrders/index';
import { SERVICES_TYPES, REQUESTS_SCREENS } from "../../common/constants"
import TourOrders from './TourOrders/index';
import './style.css'
import TeleConsultation from './TeleConsultation'



const Orders = () => {
    const [tabIndex, setTabIndex] = useState(1)
    const [requestsCount, setRequestsCount] = useState({})
    const { t, i18n } = useTranslation()

    const fetchCounts = () => {
        GetRequestsCounts(i18n.language).then(res => {
            setRequestsCount(res.data)
        }).catch(err => {
        })
    }

    useEffect(() => {
        fetchCounts()
        const IntervalHits = setInterval(() => fetchCounts(), 300000)
        return () => clearInterval(IntervalHits)
    }, [tabIndex])
    return (
        <>
            <div className='table-Container'>
                <div className="taps">
                    {
                        REQUESTS_SCREENS.map((tab, index) =>
                            <Fragment key={index}>
                                {
                                    <div onClick={e => setTabIndex(tab.title)} className={`${(tab.title) == tabIndex ? 'active' : ''} tap`}>
                                        <div className='action'>{t(tab.content)}</div>
                                        {
                                            Object.keys(requestsCount)?.includes(tab.content) &&
                                            <div className='number'>{requestsCount[tab.content]}</div>
                                        }
                                    </div>
                                }
                            </Fragment>
                        )
                    }
                </div>
                {
                    tabIndex == SERVICES_TYPES.Medical &&
                    <FollowRequests />
                }
                {
                    tabIndex == SERVICES_TYPES.Hotel &&
                    <HotelOrders />
                }
                {
                    tabIndex == SERVICES_TYPES.Transportation &&
                    <TransactionOrders />
                }
                {
                    tabIndex == SERVICES_TYPES.Tour &&
                    <TourOrders />
                }
                {/* {
                tabIndex == SERVICES_TYPES.TeleConsultation &&
                    <TeleConsultation />
                } */}
            </div>
        </>
    )
}
export default Orders 