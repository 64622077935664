import { useState, memo } from "react";
import { useAsyncDebounce } from "react-table";
import { useTranslation } from "react-i18next";

const ColumnFilter = ({ column }) => {
    const { filterValue, setFilter } = column;
    const [value, setValue] = useState(filterValue);
    const { t } = useTranslation()

    const onChange = useAsyncDebounce((value) => {
        setFilter(value || undefined);
    }, 500);

    return (
        <span>
            <input
                className="filterBox"
                placeholder={t("Search")}
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </span>
    );
};

export default memo(ColumnFilter);