import React, { useState, useMemo, useRef, useCallback, useContext, memo } from 'react';
import { Context } from "../../context";
import Table from '../../component/table';
import { GetAllRoles, DeleteRole, ExportRolesExcel } from "../../requests";
import { toast } from 'react-toastify';
import AddEditRole from './CURole';
import { useTranslation } from "react-i18next";
import { GetPrivileges } from "../../utils";
import { ConfirmAlert } from '../../component/confirmAlert';
import ColumnFilter from '../../component/table/ColumnFilter';

const RolesC = () => {
    const [RoleID, setRoleID] = useState(null);
    const [data, setData] = useState([])
    const [TotalRows, setTotalRows] = useState(0)
    const [reload, toggleReload] = useState(false);
    const [insertModalFisrtLoad, setInsertModalFisrtLoad] = useState(false);

    const { setLoading, authorizedPages } = useContext(Context)
    const { t, i18n } = useTranslation()

    const insertModal = useRef("insertRoleModal")
    const updateModal = useRef("UpdateRoleModal")
    const fetchIdRef = useRef(0)

    const privileges = useMemo(() => {
        return GetPrivileges(authorizedPages, 2)
    }, [authorizedPages])

    const columns = useMemo(
        () => [
            {
                Header: t('Name'),
                accessor: 'name',
                Filter: ColumnFilter,
            },
            {
                Header: t('English Name'),
                accessor: 'nameEN',
                Filter: ColumnFilter,
            },
            {
                id: 'actions',
                Header: '',
                accessor: 'actions',
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            {privileges?.canEdit &&
                                <button className='update' onClick={() => { updateRole(original.id) }} >
                                    <i className='fas fa-edit'></i>
                                </button>
                            }

                            {privileges?.canDelete &&
                                <button className='delete' onClick={() => deleteRole(original.id)}>
                                    <i className='fas fa-trash'></i>
                                </button >
                            }
                        </>
                    )
                }
            }
        ], [i18n.language]
    )

    const fetchData = useCallback(({ pageSize, pageIndex, filters }) => {
        const fetchId = ++fetchIdRef.current

        setLoading(l => l + 1)
        GetAllRoles(pageSize, pageIndex, filters).then(result => {
            setLoading(l => l - 1)
            if (fetchId === fetchIdRef.current && result.data.success) {
                setData(result.data.data)
                setTotalRows(result.data.totalCount)
            }
        }).catch(() => {
            setLoading(l => l - 1)
        })
    }, [reload])

    const updateRole = (roleID) => {
        setRoleID(roleID);
        updateModal.current.click()
    }

    const deleteRole = (roleID) => {
        ConfirmAlert(t("Delete Role"), "delete this Role?",
            () => {
                DeleteRole(roleID).then(res => {
                    if (res.data.success)
                        toast.success(t("Role Deleted Successfully"));
                    toggleReload(r => !r)
                })
            }, t)
    }

    const openInsertModal = () => {
        setInsertModalFisrtLoad(true)
        insertModal.current.click()
    }

    const finishUpdate = () => {
        toggleReload(r => !r)
        updateModal.current.click()
    }

    const finishInsertion = () => {
        toggleReload(r => !r)
        insertModal.current.click()
    }

    return (
        <>
            {privileges?.canAdd && (
                <>
                    <button onClick={openInsertModal} className="tableRelevantBtn ActionBtn">{t("Add Role")}</button>
                    <AddEditRole modalID={insertModal} finishOperation={finishInsertion} loadData={insertModalFisrtLoad} />
                </>
            )}

            {privileges?.canEdit &&
                <AddEditRole roleID={RoleID} modalID={updateModal} finishOperation={finishUpdate} />}

            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                TotalRows={TotalRows}
                hideActions={!privileges?.canDelete && !privileges?.canEdit}
                exportExcel={ExportRolesExcel}
            />
        </>
    );
}

export default memo(RolesC);