import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Popup from '../../../component/popup'
import { Context } from '../../../context'
import { AddUserHotels, GetOrderDetails } from '../../../requests'
import Field from '../../../component/field'
import { toast } from 'react-toastify';
import { ConfirmAlert } from '../../../component/confirmAlert'
import DatePickerC from '../../../component/DatePicker'
import { ValidatePdf } from '../../../utils'
import MultiSelect from '../../../component/multi-select'
import FileInput from '../../../component/FileInput'
import moment from 'moment'
import { HOTEL_TYPE } from '../../../common/constants'

const modalName = 'AddPricing'

const AddPricing = ({ modalID, RecordID, finishOperation, toggleReload }) => {

    const [usereRequestList, setUserRequestList] = useState([])
    const [hotelsList, setHotelsList] = useState([{
        name: '', price: '', expirationDate: '',
        HotelRequestOrderId: null
    }])

    const { t, i18n } = useTranslation()
    const { loading, setLoading } = useContext(Context)

    const [fileData, setFileData] = useState(null)

    useEffect(() => {
        if (!RecordID)
            return
        GetOrderDetails(RecordID, i18n.language).then(res => {
            let tempArr = res.data.data.map(ele => ({ label: ele.hotelCategoryValue + " " + (ele.hotelTypeId == HOTEL_TYPE.Rooms ? t('STARS') : t("BEDS")), value: ele.id }))
            setUserRequestList([...tempArr])
        }).catch(err => { })

    }, [RecordID])



    const handleSave = () => {
        let isValid = true

        hotelsList.forEach(ele => {
            if (Object.values(ele).includes('')) {
                isValid = false
                return
            }
            isValid = true
            return
        }
        )


        if (!isValid) {
            toast.error(t('Please fill all fields'))
            return
        }

        if (!ValidatePdf(fileData)) {
            toast.error(t('Please Upload pdf File'))
            return

        }

        ConfirmAlert(t("Add Pricing"),
            "Add Pricing?",
            () => {
                let dataForm = new FormData()
                let dataList = hotelsList.map(ele => ({ ...ele, expirationDate: moment(ele.expirationDate).format('YYYY-MM-DD') }))

                dataForm.append("HotelPendingOrderId", RecordID)
                dataForm.append("Hotels", JSON.stringify(dataList))
                dataForm.append("DescriptionPDF", fileData)

                setLoading(l => l + 1);

                AddUserHotels(dataForm, i18n.language).then(result => {
                    setLoading(l => l - 1);
                    finishOperation();
                    toast.success(t("Prices Added Successfully"));
                    setFileData(null)
                    setHotelsList([{
                        name: '', price: '', expirationDate: '',
                        HotelRequestOrderId: null
                    }])
                    toggleReload(e => !e)
                }).catch(() => {
                    setLoading(l => l - 1);
                })
            }, t)
    }

    const AddHotel = () => {
        let tempArr = hotelsList
        tempArr.push({
            name: '', price: '', expirationDate: '',
            HotelRequestOrderId: null
        })
        setHotelsList([...tempArr])
    }

    const handleInputChange = (val, propName, i) => {
        let tempArr = hotelsList
        let tempObj = tempArr[i]
        tempObj[propName] = val
        tempArr[i] = tempObj
        setHotelsList([...tempArr])
    }


    const rmHotelPricing = (i) => {
        let tempArr = hotelsList.filter((ele, index) => index != i)
        setHotelsList([...tempArr])
    }


    return (
        <>
            <button ref={modalID} data-toggle="modal" data-target={`#${modalName + RecordID}`} hidden></button>
            <Popup name={modalName + RecordID} large={true} title={t("Add Pricing")}
                PopupName={t("Pricing")} t={t}>

                {
                    hotelsList.map((ele, index) => {
                        return (
                            <div className={`row HotelPricing ${index > 0 && "seperator"} `} key={index}>
                                <Field className={'col-md-6 col-sm-12'} value={ele?.name} setValue={(val) => handleInputChange(val, 'name', index)} label={t("Name")} placeholder={t("Enter Name")} />
                                <Field className={'col-md-6 col-sm-12'} value={ele?.price} setValue={(val) => handleInputChange(val, 'price', index)} label={t("Price")} placeholder={t("Enter Price")} />
                                <DatePickerC minDate={new Date()} className={'col-md-6 col-sm-12'} label={"Expiration Date"} isSingle dates={ele?.expirationDate} Apply={(val) => handleInputChange(val, 'expirationDate', index)} placeholder={t('Enter ExpirationDate')} />
                                <MultiSelect isMulti={false} label={t("Accomodation-request")} options={usereRequestList} selectedOptions={ele.HotelRequestOrderId ? usereRequestList?.find(request => request.id === ele.HotelRequestOrderId) : null} setSelectedOptions={(val) => handleInputChange(val.value, "HotelRequestOrderId", index)} />
                                {index != 0 ?
                                    < div className='deletePricingFields' onClick={() => rmHotelPricing(index)}>x</div>
                                    : ""
                                }
                            </div>
                        )
                    })
                }
                <button className='ActionBtn' onClick={AddHotel}>{t("Add Hotel")}</button>

                <hr />
                <FileInput accept={'.pdf'} setValue={setFileData} value={fileData} label={t('Add PDF')} />

                <div className="buttons update">
                    <button className="ActionBtn  my-2 my-sm-0" onClick={handleSave} disabled={loading}>
                        {t("Save")}
                    </button>
                </div>
            </Popup >
        </>
    )
}

export default AddPricing