import React, { useContext, useEffect, useRef, useState } from 'react'
import { Context } from '../../../context'
import { useTranslation } from 'react-i18next'
import { GetRequestTotalsByCountry } from '../../../requests'
import WorldMap from 'react-svg-worldmap'

const CountriesState = () => {
    const [data, setData] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [maxNameLength,setMaxNameLength] =useState()

    const { setLoading, selectedDateRange } = useContext(Context)

    const { t, i18n } = useTranslation()
    const DescriptionWidthRef = useRef()

    useEffect(() => {
        setLoading(l => l + 1)
        GetRequestTotalsByCountry(selectedDateRange[0], selectedDateRange[1], i18n.language).then(res => {
            setLoading(l => l - 1)

            setData(res.data.data)
            setTotalCount(res.data.totalCount)

        }).catch(err => {
            setLoading(l => l - 1)
        })
    }, [i18n.language, selectedDateRange])

    useEffect(() => {
        setMaxNameLength(DescriptionWidthRef.current?.offsetWidth )
    }, [])

    return (
        <div className="col-12 col-lg-8 ">
            <div className="NationalityCount">
                <div className="count">{t("Country-State")}<span>({totalCount} {t("Nationaity")})</span></div>

                <div className="Nationality" ref={DescriptionWidthRef}>

                    <div className="right">
                        <WorldMap
                            color="red"
                            valueSuffix="Person"
                            size={maxNameLength > 720 ? "lg" : maxNameLength > 540 ? 'md' : 'sm'}
                            
                            data={data.map(ele => ({ country: ele.code, value: ele.count }))}
                        />
                    </div>
                    <div className="left">
                        <div>{t("common")}</div>
                        <div className="countrys ">
                            {
                                data.slice(0, 4).map((ele, index) =>

                                    < div className="country " key={index}>
                                        <div>
                                            <div>{ele.type}</div>
                                            <div className="number"><span>{Math.floor(ele.percentage) + '%  '} </span>.  <span>{ele.count} {t("Request")}</span></div>
                                        </div>

                                    </div>
                                )}
                        </div>
                    </div>

                </div>
            </div>
        </div >
    )
}

export default CountriesState