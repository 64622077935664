import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next ,useTranslation } from 'react-i18next'
import enTranslation from './locales/en.json'
import arTranslation from './locales/ar.json'

i18n.use(initReactI18next).use(LanguageDetector)
.init({
  resources: {
    en: {
      translation: enTranslation
    },
    ar:{
      translation: arTranslation
    }
  },
  interpolation: {
    escapeValue: false
  },
  lng:"ar"
})

export default i18n