import React, { useCallback, useContext, useRef, useState } from 'react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '../../component/table'
import { Context } from '../../context'
import moment from 'moment/moment'
import { ExportTransactions, GetAllTransactions } from '../../requests'
import ColumnFilter from '../../component/table/ColumnFilter'

const Transactions = () => {

    const [data, setData] = useState([])
    const [totalRows, setTotalRows] = useState(0)

    const { setLoading } = useContext(Context)

    const fetchIdRef = useRef(0)

    const { t, i18n } = useTranslation()

    const columns = useMemo(() => [
        {
            Header: t('ID'),
            accessor: 'id',
        },
        {
            Header: t('UserName'),
            accessor: 'userName',
            Filter: ColumnFilter,
        },
        {
            Header: t('ServiceName'),
            accessor: 'serviceType',
            Filter: ColumnFilter,
        },
        {
            Header: t('Amount'),
            accessor: 'amount',
        },
        {
            Header: t('Date'),
            Cell: ({ row: { original } }) => {
                return (
                    <>
                        {moment(original.createdAt).format('YYYY-MM-DD hh:mm')}
                    </>
                )
            }
        },
    ], [i18n.language])

    const fetchData = useCallback(({ filters, pageSize, pageIndex }) => {
        const fetchId = ++fetchIdRef.current

        setLoading(l => l + 1)
        GetAllTransactions(filters, pageIndex, pageSize, i18n.language).then(result => {
            setLoading(l => l - 1)
            if (fetchId === fetchIdRef.current && result.data.success) {
                setData(result.data.data)
                setTotalRows(result.data.totalCount)
            }
        }).catch(() => {
            setLoading(l => l - 1)
        })

    }, [i18n.language])

    return (
        <div>
            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                TotalRows={totalRows}
                exportExcel={ExportTransactions}
            />
        </div>
    )
}

export default Transactions