import React, { useEffect, useState, useContext, memo } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../../component/header";
import SideBar from "../../component/sideBar";
import { useTranslation } from "react-i18next";
import { GetAuthorizedPages } from "../../requests";
import { Context } from "../../context";
import Users from '../users';
import Roles from '../roles';
import ChangePassword from '../ChangePassword';
import './style.css';
import FollowRequests from "../Orders/Medical";
import ServiceTypes from "../ServiceTypes";
import ServiceProvider from "../Hospitals";
import ServiceCategories from "../Service-Category";
import Hotels from "../Hotels";
import Transactions from "../Transactions";
import HomePage from "../Home-Page";
import Orders from "../Orders";
const Home = () => {
  const [sideBarCollapsed, toggleSideBar] = useState(false);
  const { setLoading, authorizedPages, setAuthorizedPages } = useContext(Context)
  const { i18n } = useTranslation()

  useEffect(() => {
    setLoading(l => l + 1)

    GetAuthorizedPages().then(result => {
      setLoading(l => l - 1)
      if (result.data.success)
        setAuthorizedPages(result.data.data)
    }).catch(() => {
      setLoading(l => l - 1)
    })
  }, [])

  return (
    <div className={`wrapper ${i18n.language}`}>
      <Header toggleSideBar={() => toggleSideBar((s) => !s)} />

      <div id="content" className="container-fluid">

        <SideBar data={authorizedPages} collapsed={sideBarCollapsed} />
        <div className="screen-container">
          {authorizedPages.length > 0 &&
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="users" element={<Users />} />
              <Route path="roles" element={<Roles />} />
              <Route path="changePassword" element={<ChangePassword />} />
              <Route path="/ServiceTypes" element={<ServiceTypes />} />
              <Route path="/transactions" element={<Transactions />} />
              <Route path="/ServiceProviders" element={<ServiceProvider />} />
              <Route path="/ServiceCategory" element={<ServiceCategories />} />
              <Route path="/Orders" element={<Orders />} />
            </Routes>
          }
        </div>
      </div>
    </div>
  );
};

export default memo(Home);