import React, { useState, useMemo, useRef, useCallback, useContext, memo } from 'react';
import { Context } from "../../context";
import Table from '../../component/table';
import { GetAllUsers, DeleteUser, ExportUsersExcel } from "../../requests";
import { toast } from 'react-toastify';
import AddEditUser from './CUUser';
import { useTranslation } from "react-i18next";
import { GetPrivileges } from "../../utils";
import { ConfirmAlert } from '../../component/confirmAlert';
import ColumnFilter from '../../component/table/ColumnFilter';

const UsersC = () => {
    const [UserID, setUserID] = useState(null);
    const [data, setData] = useState([])
    const [TotalRows, setTotalRows] = useState(0)
    const [reload, toggleReload] = useState(false);
    const [insertModalFisrtLoad, setInsertModalFisrtLoad] = useState(false);

    const { setLoading, authorizedPages } = useContext(Context)
    const { t, i18n } = useTranslation()
    const insertModal = useRef("insertUserModal")
    const updateModal = useRef("UpdateUserModal")
    const fetchIdRef = useRef(0)

    const privileges = useMemo(() => {
        return GetPrivileges(authorizedPages, 1)
    }, [authorizedPages])

    const columns = useMemo(
        () => [
            {
                Header: t('UserName'),
                accessor: 'username',
                Filter: ColumnFilter,
            },
            {
                Header: t('Name'),
                accessor: 'name',

            },
            {
                Header: t('Email'),
                accessor: 'email',
                Filter: ColumnFilter
            },
            {
                Header: t('Role'),
                accessor: 'roleName',
                // sticky:'left'
            },
            {
                id: 'actions',
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            {privileges?.canEdit &&
                                <button className='update' onClick={() => { updateUser(original.id) }} >
                                    <i className='fas fa-edit'></i>
                                </button>
                             } 

                             {privileges?.canDelete && 
                                <button className='delete' onClick={() => deleteUser(original.id)} >
                                    <i className='fas fa-trash'></i>
                                </button>
                             } 
                        </>
                    )
                }
            }
        ], [i18n.language]
    )

    const fetchData = useCallback(({ pageSize, pageIndex, filters }) => {
        const fetchId = ++fetchIdRef.current

        setLoading(l => l + 1)
        GetAllUsers(pageSize, pageIndex, filters).then(result => {
            setLoading(l => l - 1)
            if (fetchId === fetchIdRef.current && result.data.success) {
                setData(result.data.data)
                setTotalRows(result.data.totalCount)
            }
        }).catch(() => {
            setLoading(l => l - 1)
        })
    }, [reload])

    const updateUser = (userID) => {
        setUserID(userID);
        updateModal.current.click()
    }

    const deleteUser = (userID) => {
        ConfirmAlert(t("Delete User"), "delete this User?",
            () => {
                DeleteUser(userID).then(res => {
                    if (res.data.success)
                        toast.success(t("User Deleted Successfully"));
                    toggleReload(r => !r)
                })
            }, t)
    }

    const openInsertModal = () => {
        setInsertModalFisrtLoad(true)
        insertModal.current.click()
    }

    const finishUpdate = () => {
        toggleReload(r => !r)
        updateModal.current.click()
    }

    const finishInsertion = () => {
        toggleReload(r => !r)
        insertModal.current.click()
    }

    return (
        <>
            {privileges?.canAdd && (
                <>
                    <button onClick={openInsertModal} className="tableRelevantBtn ActionBtn">{t("Add User")}</button>
                    <AddEditUser modalID={insertModal} finishOperation={finishInsertion} loadData={insertModalFisrtLoad} />
                </>
            )}

            {privileges?.canEdit &&
                <AddEditUser userID={UserID} modalID={updateModal} finishOperation={finishUpdate} />}

            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                TotalRows={TotalRows}
                hideActions={!privileges?.canDelete && !privileges?.canEdit}
                exportExcel={ExportUsersExcel}
            />
        </>
    );
}

export default memo(UsersC);