import moment from "moment/moment";
import React, { useState, createContext } from "react";

export const Context = createContext();

const ContextC = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("ut") ? true : false);
  const [selectedDateRange, setSelectedDateRange] = useState([moment().startOf('month').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')])
  const [userName, setUserName] = useState();
  const [loading, setLoading] = useState(0);
  const [authorizedPages, setAuthorizedPages] = useState([]);
  const [showDateRange, setShowDateRange] = useState(false);

  let state = {
    isLoggedIn,
    setIsLoggedIn,
    userName,
    setUserName,
    loading,
    setLoading,
    authorizedPages,
    selectedDateRange,
    setSelectedDateRange,
    setAuthorizedPages,
    setShowDateRange,
    showDateRange
  };

  return <Context.Provider value={state}>{props.children}</Context.Provider>;
};

export default ContextC;