import React, { useState, useEffect, useContext, useRef, useMemo, memo } from 'react';
import Popup from '../../component/popup';
import { AddRole, UpdateRole, GetRoleDetails, GetPagesLookup, GetRolesPrivilegesByRoleID } from '../../requests'
import { toast } from 'react-toastify';
import { useTranslation } from "react-i18next";
import { Context } from "../../context";
import Field from '../../component/field';
import Table from '../../component/table';
import { ConfirmAlert } from '../../component/confirmAlert';

const modalName = "CURoleModal"

const CURole = ({ roleID, modalID, finishOperation, loadData }) => {
    const [name, setName] = useState('')
    const [nameEN, setNameEN] = useState('')
    const [disableRead, setdisableRead] = useState(false)
    const [Pages, setPsges] = useState([])
    const [RolePrivileges, setRolePrivileges] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [TotalRows, setTotalRows] = useState(0)

    const { setLoading } = useContext(Context)
    const { t, i18n } = useTranslation()
    const loadedData = useRef(false)

    useEffect(() => {
        if (loadedData.current || (!roleID && !loadData))
            return

        loadedData.current = true

        setLoading(l => l + 1)
        GetPagesLookup().then(result => {
            setLoading(l => l - 1)
            if (result.data.success) {
                setPsges(result.data.data)
                setTotalRows(result.data.totalCount)
            }
        }).catch(error => {
            setLoading(l => l - 1)
            toast.error(error.response?.data.message || error.message)
        })
    }, [roleID, loadData])

    useEffect(() => {
        if (roleID) {
            setLoading(l => l + 1)
            GetRoleDetails(roleID).then(result => {
                setLoading(l => l - 1)
                if (result.data.success) {
                    var Role = result.data.data;
                    setName(Role.name)
                    setNameEN(Role.nameEN)
                }
            }).catch(() => {
                setLoading(l => l - 1)
            })

            GetRolesPrivilegesByRoleID(roleID).then(result => {
                if (result.data.success) {
                    setRolePrivileges(result.data.data)
                }
            })
        }
    }, [roleID])

    const saveRole = () => {
        if (name.trim() === '' || nameEN.trim() === '' || RolePrivileges.length === 0) {
            toast.error(t("Please fill all fields"))
            return
        }

        ConfirmAlert(roleID ? t("Edit Role") : t("Add Role"),
            roleID ? "Edit this Role?" : "Add this Role?",
            () => {
                const RoleData = {
                    name, nameEN, rolesPriviledges: RolePrivileges
                }

                setIsLoading(true);
                if (roleID)
                    UpdateRole(roleID, RoleData).then(result => {
                        setIsLoading(false)
                        if (result.data.success) {
                            toast.success(t("Role Edited Successfully"))
                            finishOperation();
                        }
                    }).catch(() => {
                        setIsLoading(false);
                    })
                else
                    AddRole(RoleData).then(result => {
                        setIsLoading(false);
                        if (result.data.success) {
                            finishOperation();
                            setIsLoading(false);
                            toast.success(t("Role Added Successfully"));
                        }
                    }).catch(() => {
                        setIsLoading(false)
                    })
            }, t)
    }

    const resetAllFields = () => {
        setName('')
        setNameEN('')
        setRolePrivileges([])
    }


    const removePagePriviledges = (PageCurrentPriviliges, action) => {
        for (const [key, value] of Object.entries(PageCurrentPriviliges)) {
            if (value && key != action && key != 'controllerNameID') {
                return false
            }
        }
        return true
    }

    const checked = (controllerNameID, Action) => {
        let RowPrivileges = RolePrivileges.find(ele => ele.controllerNameID == controllerNameID)

        if (!RowPrivileges)
            return false

        return RowPrivileges[Action]
    }


    const handleDisabled = (controllerNameID) => {
        let RowPrivileges = RolePrivileges.find(ele => ele.controllerNameID == controllerNameID) || {}
        let isGetChecked = false

        for (const [key, value] of Object.entries(RowPrivileges)) {
            if (value && key != 'canGet' && key != 'controllerNameID' && key !='roleID') {
                isGetChecked = true
                break;
            }
        }
        return isGetChecked
    }

    const handleCheckboxChange = (e, controllerNameID, Action) => {
        let tempScreenObj = RolePrivileges.find(ele => ele.controllerNameID === controllerNameID) || {}
        let filteredArr = RolePrivileges.filter(ele => ele.controllerNameID !== controllerNameID)

        tempScreenObj[Action] = e.target.checked
        tempScreenObj.controllerNameID = controllerNameID
        // tempScreenObj.role = roleID

        if (e.target.checked) {
            tempScreenObj.canGet = true
        }

        if (!e.target.checked && removePagePriviledges(tempScreenObj, Action)) {
            setRolePrivileges([...filteredArr])
            return
        }

        filteredArr.push(tempScreenObj)
        setRolePrivileges([...filteredArr])
    }

    const columns = useMemo(
        () => [
            {
                Header: t('Name'),
                accessor: 'label',
            },
            {
                Header: t('Read'),
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            <input type="checkbox" name="read" disabled={handleDisabled(original.value)} checked={checked(original.value, 'canGet')} onChange={(e) => handleCheckboxChange(e, original.value, 'canGet')} />
                        </>
                    )
                }
            },
            {
                Header: t('Write'),
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            <input type="checkbox" name="write" checked={checked(original.value, 'canAdd')} onChange={(e) => handleCheckboxChange(e, original.value, 'canAdd')} />
                        </>
                    )
                }
            },
            {
                Header: t('Edit'),
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            <input type="checkbox" name="edit" checked={checked(original.value, 'canEdit')} onChange={(e) => handleCheckboxChange(e, original.value, 'canEdit')} />
                        </>
                    )
                }
            },
            {
                Header: t('Delete'),
                Cell: ({ row: { original } }) => {
                    return (
                        <>
                            <input type="checkbox" name="delete" checked={checked(original.value, 'canDelete')} onChange={(e) => handleCheckboxChange(e, original.value, 'canDelete')} />
                        </>
                    )
                }
            }
        ], [i18n.language, disableRead, RolePrivileges]
    )

    return (
        <>
            <button ref={modalID} data-toggle="modal" data-target={`#${modalName + roleID}`} hidden></button>
            <Popup name={modalName + roleID} large={true} title={roleID ? t("Edit Role") : t("Add Role")}
                onClose={roleID ? undefined : resetAllFields}>

                <Field id="name" value={name} setValue={setName} label={t("Name")} />
                <Field id="nameEN" value={nameEN} setValue={setNameEN} label={t("English Name")} />

                <Table
                    columns={columns}
                    data={Pages}
                    TotalRows={TotalRows}
                    isPagination={false}
                    page_size={TotalRows}
                />

                <div className="buttons">
                    <button className=" my-2 my-sm-0 ActionBtn" onClick={saveRole} disabled={isLoading}>
                        <span className={isLoading ? "spinner-border spinner-border-sm" : ""} role="status" aria-hidden="true"></span>
                        {roleID ? t("Update") : t("Save")}
                    </button>
                </div>
            </Popup>
        </>
    );
}

export default memo(CURole);
