
import { Icon } from "@iconify/react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { useContext, useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import ChartHeader from "./ChartHeader"
import { GetRequestTotals } from "../../../requests/Home"
import { Context } from "../../../context"

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend
)

const options = {
  plugins: {
    legend: {
      display: false
    }
  }
}


const DoughnutChart = ({ ColorPallete }) => {
  const [Count, setCount] = useState([])
  const [Label, setLabel] = useState([])
  const [dataObj, setDataObj] = useState([])

  const { t, i18n } = useTranslation()

  const { setLoading ,selectedDateRange} = useContext(Context)


  useEffect(() => {
    setLabel([])
    setCount([])
    setLoading(l => l + 1)
    GetRequestTotals(selectedDateRange[0] ,selectedDateRange[1],i18n.language).then(res => {
      setDataObj(res.data)
      setLabel(res.data.data.map(ele => ele.type))
      setCount(res.data.data.map(ele => ele.count))
      setLoading(l => l - 1)
    }).catch(err => {
      setLoading(l => l - 1)
    })
  }, [i18n.language,selectedDateRange])

  let data = {
    labels: Label,
    datasets: [{
      label: 'Poll',
      data: Count,
      backgroundColor: dataObj?.data?.map(o => ColorPallete.find(ele => ele.id == o.id).color)
      , cutout: '80%',
      aspectRedio: 0.5
    }]
  }

  let plugins = [{
    id: 'textCenter',
    beforeDatasetsDraw(chart) {
      const { ctx } = chart;
      ctx.save()
      ctx.font = '20px poppins'
      ctx.fillStyle = 'gray'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(`${dataObj.totalCount}  ${t("Request")}`, chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y)
    }
  }]
  
  return (
    <div className="col-12 col-lg-4 p-lg-0">
      <div className="orders">

        <ChartHeader Name={t('TotalRequests')}>
          <div className="NavigateTo">
            <Link to="/Orders">
              <Icon icon='solar:alt-arrow-left-line-duotone' className="NavigationToIcon" />
            </Link>
          </div>
        </ChartHeader>

        <div className="totalOfOrder">
          <div className="canvasdoughnutContainer">
            {
              Count.length && Label.length ?
                < Doughnut data={data} options={options} plugins={plugins} />
                : <span>{t("NoDataFound")}</span>
            }
          </div>
        </div>
        <div className="type-order">
          {
            dataObj.data?.map(ele => (
              <div className="order" key={ele.id}>
                <div className="RequestName">
                  <div className="Bullet-color" style={{ backgroundColor: ColorPallete.find(o => o.id == ele.id).color }}>
                  </div>
                  <span>
                    {t("RequestsText")} {ele.type}
                  </span>
                </div>
                <div className="orderTypeTotal">
                  <span>{ele.count}</span>
                  <span>{t("Request")}</span>
                </div>

              </div>
            ))
          }
        </div>
      </div>
    </div >

  )
}

export default DoughnutChart