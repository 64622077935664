import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { Context } from '../../../context'
import { useTranslation } from 'react-i18next'
import Table from '../../../component/table'
import AddPricing from './AddPricing'
import { GetAllMedicalPendingOrders } from '../../../requests'
import ColumnFilter from '../../../component/table/ColumnFilter'
import { Paths, REQUEST_STATUS } from '../../../common/constants'
import { FormateDate } from '../../../utils';

const FollowRequests = () => {

  const [data, setData] = useState([])
  const [recordID, setRecordID] = useState(null)
  const [reload, toggleReload] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
  const [isRead, setIsRead] = useState(false)
  const AddPricingRef = useRef(null)
  const fetchIdRef = useRef(0)

  const { setLoading } = useContext(Context)

  const { t, i18n } = useTranslation()

  const columns = useMemo(() => [
    {
      Header: t('ID'),
      accessor: 'orderId',
    },
    {
      Header: t('orderDate'),
      Cell: ({ row: { original } }) => {
        return (
          <>
            {
              original.orderDate ?
                FormateDate(original.orderDate, t)
                : "-"
            }
          </>
        )
      }
    },
    {
      Header: t('userName'),
      accessor: 'userName',
      Filter: ColumnFilter
    },
    {
      Header: t('ServiceName'),
      accessor: 'serviceName',
      Filter: ColumnFilter
    },
    {
      Header: t('MedicalRecord'),
      Cell: ({ row: { original } }) => {
        return (
          <>
            {
              original.medicalRecord ?
                <a className='update' href={Paths.WebServer + original.medicalRecord} target='blank'  >
                  {t('Click to View')}
                </a> : "-"
            }
          </>
        )
      }
    },
    {
      Header: t('Status'),
      Cell: ({ row: { original } }) => {
        return (
          <>
            {
              <div className={`boxstatus status-order${original.orderStatusId}`}>
                <span className={`textstatus status${original.orderStatusId}`}>{original.orderStatus}</span>
              </div >
            }
          </>
        )
      }
    },
    {
      id: 'Actions',
      Cell: ({ row: { original } }) => {
        return (
          <>
            {original.orderStatusId == REQUEST_STATUS.PendingRequest &&
              <button className='update' titel={t("Pricing")} onClick={() => handlePricing(original.id)}>
                <i className='fas fa-edit'></i>
              </button>
            }

            {original.orderStatusId != REQUEST_STATUS.PendingRequest ?
              <button className='update' title={t("Details")} onClick={() => handlePricing(original.id, true)}>
                <i className='fas fa-eye'></i>
              </button> : ''
            }

          </>
        )
      }
    }
  ], [i18n.language])

  const fetchData = useCallback(({ pageSize, pageIndex, filters }) => {
    const fetchId = ++fetchIdRef.current

    setLoading(l => l + 1)
    GetAllMedicalPendingOrders(filters, pageIndex, pageSize, i18n.language).then(result => {
      setLoading(l => l - 1)
      if (fetchId === fetchIdRef.current && result.data.success) {
        setData(result.data.data)
        setTotalRows(result.data.totalCount)
      }
    }).catch(() => {
      setLoading(l => l - 1)
    })

  }, [reload, i18n.language])

  const handlePricing = (id, isRead) => {
    AddPricingRef.current.click()
    setRecordID(id)
    setIsRead(isRead)
  }


  return (
    <div className='Clearance-TableContainer'>
      <AddPricing isRead={isRead} setRecordId={setRecordID} modalID={AddPricingRef} reload={reload} toggleReload={toggleReload} RecordID={recordID} finishOperation={() => AddPricingRef.current.click()} />

      <Table
        columns={columns}
        data={data}
        fetchData={fetchData}
        TotalRows={totalRows}
      />
    </div>
  )

}

export default FollowRequests