import React from 'react'
import { useTranslation } from 'react-i18next'

const ChartHeader = ({ totals, Name, children }) => {
    const { t } = useTranslation()
    return (
        <div className='countOfOrder'>
            <div className='TotalSales'> {t(Name)} <span className="number">{totals}</span></div>
            {children}
        </div>
    )
}

export default ChartHeader