import { CONTROLLER_NAMES } from '../../common/constants'

export const Menu = [

    {
        id: `-${CONTROLLER_NAMES.User}-${CONTROLLER_NAMES.Roles}-`,
        icon: "solar:users-group-two-rounded-bold",
        name: "usersManagement",
        path: "/new",
        children: [{
            id: CONTROLLER_NAMES.User,
            icon: "fa-user-shield",
            path: "/users",
            name: "Users"
        },
        {
            id: CONTROLLER_NAMES.Roles,
            icon: "fa-user-tag",
            path: "/roles",
            name: "Roles"
        }]
    },
    {
        id: CONTROLLER_NAMES.MedicalPendingOrder,
        icon: "solar:documents-bold",
        path: "/Orders",
        name: "Requests",
    },
    {
        id: CONTROLLER_NAMES.ServiceCategory,
        icon: "ri:service-line",
        path: "/ServiceCategory",
        name: "ServiceCategory",
    },
    {
        id: CONTROLLER_NAMES.Hospital,
        icon: "solar:map-point-hospital-outline",
        path: "/ServiceProviders",
        name: "Hospitals",
    },
    {
        id: CONTROLLER_NAMES.Transactions,
        icon: "solar:wallet-money-bold",
        path: "/transactions",
        name: "transactions",
    },
]