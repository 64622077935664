import fileDownload from "js-file-download";
import { APICall } from "./index";

const ControllerName = "Transactions";

export const GetAllTransactions = (filters, pageIndex, pageSize, lang) => {
    console.log({...filters});
    
    const userName = filters?.find(ele => ele.id == 'userName')?.value || ''
    const serviceName = filters?.find(ele => ele.id == 'serviceType')?.value || ''

  return APICall("get", `${ControllerName}?userName=${userName}&serviceName=${serviceName}&page=${pageIndex}&pageLength=${pageSize}&sort=Id:-1`, {}, lang);
};


export const ExportTransactions = (filters, lang) => {
    const userName = filters?.find(ele => ele.id == 'userName')?.value || ''
    const serviceName = filters?.find(ele => ele.id == 'serviceType')?.value || ''

    return APICall("get", `${ControllerName}/excel?UserName=${userName}&ServiceName${serviceName}&sort=Id:-1`, {}, lang)
        .then(result => {
            return new Promise((resolve) => {
                fileDownload(result.data, 'Transactions.xlsx')
                resolve()
            })
        });
};