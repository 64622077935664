import React, { useEffect, useState } from 'react'
import Field from '../../../component/field'
import { useTranslation } from 'react-i18next'

const PriceInput = ({ label, medicalPrice, HospitalsPricing, reload, Id, setHospitalsPricing, Index }) => {
    const [price, setPrice] = useState(null)

    const { t } = useTranslation()

    useEffect(() => {
        let tempArr = HospitalsPricing
        let tempObj = HospitalsPricing[Index] || {}
        tempObj.HospitalPrice = price
        tempObj.HospitalId = Id
        tempArr[Index] = tempObj
        setHospitalsPricing(tempArr)
    }, [price])

    useEffect(() => {
        setPrice('')

    }, [reload, HospitalsPricing])


    return (
        <div className='fieldsContainer' key={Id}>
            <Field type="text" disabled value={label} label={t('Name')} className='col-lg-6' placeholder={t("Name")} />
            <Field disabled={!!medicalPrice} className={'col-lg-6'} label={t("Price")} value={price || medicalPrice} setValue={setPrice} placeholder={t('Price')} />
        </div>
    )
}

export default PriceInput