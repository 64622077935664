import { APICall } from './index';

const ControllerName = "Order"



export const GetRequestsCounts = (lang) => {

    return APICall("get", ControllerName + "/getAllTotalCount", {}, lang)
}

export const GetAllHotels = (id, pageSize, pageIndex, lang) => {

    return APICall("get", ControllerName + `/getAllHotels/${id}?page=${pageIndex}&pageLength=${pageSize}&sort=Id:-1`, {}, lang)
}