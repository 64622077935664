import { APICall } from ".";

const ControllerName = "Provider"

export const GetHospitals = (filters, pageIndex, pageSize, lang) => {
    const name = filters?.find(ele => ele.id == 'name')?.value || ''
    const nameEn = filters?.find(ele => ele.id == 'nameEN')?.value || ''
    return APICall('get', `${ControllerName}?name=${name}&nameEN=${nameEn}&page=${pageIndex}&pageLength=${pageSize}&sort=Id:-1`, {}, lang)
}

export const GetHospitalsById = (Id,lang) => {
    return APICall('get', `${ControllerName}/${Id}`, {},lang)
}

export const AddHospitals = (HospitalsObj,lang) => {
    return APICall('post', `${ControllerName}/`,HospitalsObj, lang)
}

export const updateHospitalById = (Id ,HospitalsObj,lang) => {
    return APICall('put', `${ControllerName}/${Id}`,HospitalsObj, lang)
}

export const DeleteHospitals = (Id,lang ) => {
    return APICall('delete', `${ControllerName}/${Id}`,{}, lang)
}