import { useEffect, useMemo, useState, useContext } from 'react'
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, TimeScale } from 'chart.js';
import { Line } from "react-chartjs-2"
import { useTranslation } from 'react-i18next'
import moment from 'moment/moment';
import { GetSalesDetails } from "../../../requests"
import { Context } from "../../../context"


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

const options = {
    plugins: {
        legend: {
            display: false
        }
    },
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
        }
    }
}

const LineChart = ({ ColorPallete, Lines }) => {

    const [salesDetails, setSalesDetails] = useState([])
    const [dateList, setDateList] = useState([])
    const [isPending, setIsPending] = useState(false)

    const { setLoading, selectedDateRange } = useContext(Context)

    const { t } = useTranslation()

    const LineChartData = Lines?.map((type, index) => (
        {
            label: '',
            data: dateList?.map((date) => {
                const salesOnDay = salesDetails.find((serviceType) => serviceType.serviceTypeId === type.id && serviceType.date === date);
                return salesOnDay ? salesOnDay.value : 0; 
            }),
            fill: false,
            tension: 0.1,
            borderColor: ColorPallete.find(col => type.id == col.id)?.color,

        }))


    const data = {
        labels: dateList?.map(ele => moment(ele).format('YYYY-MM-DD')),
        datasets: [...LineChartData, devicePixelRatio]
    }


    useEffect(() => {
        setLoading(l => l + 1)
        setIsPending(e => !e)
        GetSalesDetails(selectedDateRange[0], selectedDateRange[1]).then(res => {
            let tempDateList = []
            res.data.data.map(ele => {
                if (tempDateList.indexOf(ele.date) == -1)
                    tempDateList.push(ele.date)

            })
            setSalesDetails(res.data.data)
            setDateList([...tempDateList])
            setLoading(l => l - 1)
            setIsPending(e => !e)

        }).catch(err => {
            setLoading(l => l - 1)
        })
    }, [selectedDateRange])

    return (
        <div className='InfoCard AccountantChart'>
            <div className='ChartSection'>
                <div className='AccountantChartConatiner'>
                    <div className='chartParent'>
                        {!isPending ?
                            <Line data={data} options={options} />
                            : t('NoDataFound')}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LineChart