import React, { memo, useState } from 'react';
import './style.css';
import { Icon } from '@iconify/react/dist/iconify.js';

const Field = ({ id, label, value, setValue, type, placeholder, disabled, className }) => {

    const [showPassword, setShowPassword] = useState(false)

   
    return (

        <div className={`form-group ${type == 'password'} ${className}`}>
            <label htmlFor={id}>{label}</label>
            <>
                <input  placeholder={placeholder} disabled={disabled} className="form-control input" type={!showPassword ? type : 'text'} id={id} value={value} onChange={(e) => { type != 'file' ? setValue(e.target.value) : setValue(e.target.files[0]) }} />
                {type === 'password' ? <Icon className='EyeSign' onClick={() => setShowPassword(e => !e)} icon={!showPassword ? "fa:eye" : 'fa:eye-slash'} width="1.02em" height="1.02em" style={{ color: "black" }} /> : ''}
            </>
        </div>
    )
}
export default memo(Field);