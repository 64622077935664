import React, { Fragment, useContext, useEffect, useState, memo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Menu } from './menu';
import { Context } from "../../context";
import { Icon } from '@iconify/react';
import './style.css';
const toolTipAtrributes = (name, collapsed) => {
    if (!collapsed) {
        return {
            "data-tooltip-id": "my-tooltip",
            "data-tooltip-content": name,
            "data-tooltip-place": 'left'
        }
    }

}
const Sidebar = ({ collapsed }) => {
    const { pathname } = useLocation()
    const [currentDropdown, setCurrentDropdown] = useState(/\/[a-z]+/.exec(pathname) ? /\/[a-z]+/.exec(pathname)[0] : null)
    const { authorizedPages } = useContext(Context)
    const { t, i18n } = useTranslation()


    return (
        <nav id="sidebar" className={`${i18n.language} ${collapsed ? 'collapsedSideBar' : 'active '}`} >
            <ul className="list-unstyled components">
                <li {...toolTipAtrributes(t("home"), collapsed)}>
                    <NavLink exact="true" to="/" activeclassname="active" className='menu'>
                        <Icon icon="fluent:home-20-regular" className="SideBarIcons" />
                        <span>{t("home")}</span>
                    </NavLink>
                </li>

                {authorizedPages.length > 0 && Menu.map(m =>
                    ((m.children && authorizedPages.find(p => m.id.includes('-' + p.id + '-')))||m.id ==0) ?
                        <Fragment key={m.id}>
                            <div className='dropDownContainer' activeclassname="active" key={m.id} >

                                <li className={`${currentDropdown == m.path && "active" } menu`} onClick={() => setCurrentDropdown(d => d === m.path ? '' : m.path)}
                                    {...toolTipAtrributes(t(m.name), collapsed)} >
                                    <Icon icon={m.icon} className="SideBarIcons" />
                                    <span className="ItemName">{t(m.name)}</span>
                                    
                                </li>

                                {currentDropdown == m.path  &&
                                    <div className={collapsed ? 'sublistContainer' : "floated sublistContainer"}>
                                        {m.children.map(c =>
                                            (authorizedPages.find(p => p.id === c.id))  &&
                                            <li key={m.id + c.id} className='sub-menu' >
                                                <NavLink to={c.path} className="sub-menuLink" activeclassname="active"
                                                    onClick={() => !collapsed && setCurrentDropdown(false)}>
                                                    {
                                                        <span className='sublistText' >{t(c.name)}</span>
                                                    }
                                                </NavLink>
                                            </li>
                                        )}
                                    </div>
                                }
                            </div>

                        </Fragment> :

                        (m.id == 0 || authorizedPages.find(p => p.id === m.id)) &&
                        <li  key={m.id} onClick={() => setCurrentDropdown('')}
                            {...toolTipAtrributes(t(m.name), collapsed)} >
                            <NavLink to={m.path} activeclassname="active" className='menu' >
                                <Icon icon={m.icon} alt="icon" className="SideBarIcons" />
                                <span className='ItemName'>{t(m.name)}</span>
                            </NavLink>
                        </li>
                )}
            </ul>
        </nav >
    );
}

export default memo(Sidebar);
