import React from 'react'
import { ValidateFieldLang } from '../../utils'
import "./style.css"

const TextArea = ({ type, label, value, setValue }) => {
    const handleChange = (e) => {
        if (ValidateFieldLang(e.target.value, type))
            setValue(e.target.value)
    }
    return (
        <div className='text-container col-lg-6  col-md-12'>
            <label>
                {label}
            </label>
            <textarea className={'text-area col-12'} value={value} onChange={handleChange} ></textarea>
        </div>
    )
}

export default TextArea