import React, { useContext, memo } from 'react';
import RingLoader from "react-spinners/RingLoader";
import { Context } from '../../context';
import './style.css';

const Spinner = () => {
    const { loading } = useContext(Context)

    return (
        <div id={loading ? "spinner" : ""}  >
            <RingLoader
                size={100}
                color={"#3e5266"}
                loading={loading}
            />
        </div>
    );
} 

export default memo(Spinner);