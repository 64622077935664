import { APICall } from ".";

const ControllerName = "MedicalPendingOrder"

export const GetAllMedicalPendingOrders = (filters, pageIndex, pageSize, lang) => {
    const userName = filters?.find(ele => ele.id == 'userName')?.value || ''
    const serviceName = filters?.find(ele => ele.id == 'serviceName')?.value || ''
    return APICall('get', `${ControllerName}?UserName=${userName}&ServiceName=${serviceName}&page=${pageIndex}&pageLength=${pageSize}&sort=Id:-1`, {}, lang)
}
export const GetMedicalPendingOrderById = (Id) => {
    return APICall('get', `${ControllerName}/${Id}`, {})
}
export const AddHospitalPrices = (Id ,formData) => {
    return APICall('put', `${ControllerName}/updateHospitalsPrices/${Id}`, formData)
}