import React, { useContext, useEffect, useState } from 'react'
import { GetTotalProviders, GetTotalUsers } from '../../../requests'
import { useTranslation } from 'react-i18next'
import { Context } from '../../../context'
import { Icon } from '@iconify/react/dist/iconify.js'

const TotalAssets = () => {

  const [totalHospital, setTotalHospitals] = useState(0)
  const [totalUsers, setTotalUsers] = useState(0)

  const { setLoading ,selectedDateRange} = useContext(Context)

  const { t } = useTranslation()

  useEffect(() => {
    setLoading(l => l + 2)

    GetTotalProviders(selectedDateRange[0] ,selectedDateRange[1]).then(res => {
      setTotalHospitals(res.data.data)
      setLoading(l => l - 1)
    }).catch(err => {
      setLoading(l => l - 1)
    })

    GetTotalUsers(selectedDateRange[0] ,selectedDateRange[1]).then(res => {
      setTotalUsers(res.data.data)
      setLoading(l => l - 1)
    }).catch(err => {
      setLoading(l => l - 1)
    })
  }, [selectedDateRange])

  return (
    <div className='assets-row'>
      <div className=" col-lg-3 col-md-6 col-sm-6 mb-2 ">
        <div className=" total bg-white roundedBorders d-flex flex-row p-3 ">
          <div className=" imgOfTotal "><Icon icon='solar:hospital-bold' className='asset-icon'  /> </div>
          <div className="TotalOf mr-3">
            <div>{t("TotalProviders")}</div>
            <div className="NumberOfTotal">{totalHospital}</div>
          </div>
        </div>
      </div>

      <div className=" col-lg-3 col-md-6 col-sm-6 mb-2 ">
        <div className=" total bg-white roundedBorders d-flex flex-row  p-3 ">
          <div className=" imgOfTotal "><Icon icon='mdi:users-group'  className='asset-icon' /></div>
          <div className="TotalOf mr-3">
            <div>{t("totalUsers")}</div>
            <div className="NumberOfTotal">{totalUsers}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalAssets